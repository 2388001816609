import React from 'react';
import PropTypes from 'prop-types';

function Col({ align, header, colSpan, modifier, width, children }) {
  let tagName = 'td';
  if (header) {
    tagName = 'th';
  }

  const attrs = {
    colSpan,
    width,
    className: `Table__Col Table__Col--align-${align} ${modifier}`,
  };

  return React.createElement(tagName, attrs, children);
}

Col.propTypes = {
  align: PropTypes.string,
  header: PropTypes.bool,
  colSpan: PropTypes.string,
  width: PropTypes.string,
  children: PropTypes.node,
};

Col.defaultProps = {
  align: 'left',
  header: false,
  colSpan: '0',
  width: undefined,
  children: '',
};

export default Col;

import React from 'react';
import { useConfig } from '@hummingbirdtechgroup/wings-config';
import { useMutation } from 'react-query';
import { post } from '@hummingbirdtechgroup/wings-request';
import { useHistory } from 'react-router-dom';
import Page, { Title, Footer } from '../../components/Page';
import Link from '../../components/LinkComponent';
import ErrorMessage from '../../components/ErrorMessage';
import Breadcrumbs from '../../components/Breadcrumbs';
import ClientForm from './ClientForm';

function CreateClient() {
  const history = useHistory();
  const config = useConfig();

  const { mutate, isError } = useMutation(
    data => post(config.apiUrl, `/v1/clients`, data),
    {
      onSuccess: data => history.push(`/clients/${data.uid}`),
    },
  );

  return (
    <Page name="create-client">
      <Breadcrumbs
        links={[
          {
            url: `/clients`,
            label: 'Clients',
          },
          {
            url: '/clients/create',
            label: 'Create client',
          },
        ]}
      />
      <Title>Create Client</Title>
      <ClientForm
        clientData={{}}
        onSubmit={data => mutate(data)}
        error={
          isError && (
            <ErrorMessage>
              There was an error creating the client, please check the data
              entered and try again.
            </ErrorMessage>
          )
        }
        buttonName="Create"
      />
      <Footer>
        <Link to="/clients">&lt; Back</Link>
      </Footer>
    </Page>
  );
}

export default CreateClient;

import { useMutation, useQueryClient } from 'react-query';
import { patch } from '@hummingbirdtechgroup/wings-request';
import { useConfig } from '@hummingbirdtechgroup/wings-config';

const useAddUserToClient = clientId => {
  const config = useConfig();
  const queryClient = useQueryClient();

  return useMutation(
    ({ user, role }) =>
      patch(config.apiUrl, `/v1/users/${user.uid}`, {
        role: { client_uid: clientId, role },
      }),
    {
      onSuccess: newUser => {
        const users = queryClient.getQueryData('users') || [];
        queryClient.setQueryData(
          'users',
          users.map(user => {
            if (user.uid === newUser.uid) {
              return newUser;
            }
            return user;
          }),
        );
      },
    },
  );
};

export default useAddUserToClient;

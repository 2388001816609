import { useMutation, useQueryClient } from 'react-query';
import { request } from '@hummingbirdtechgroup/wings-request';
import { useConfig } from '@hummingbirdtechgroup/wings-config';

const useUserRemove = (userUid, { onSuccess, onError }) => {
  const config = useConfig();
  const queryClient = useQueryClient();

  return useMutation(
    () => request('DELETE', config.apiUrl, `/v1/users/${userUid}`),
    {
      onSuccess: () => {
        const users = queryClient.getQueryData('users');
        queryClient.setQueryData(
          'users',
          users.filter(({ uid }) => uid !== userUid),
        );
        if (onSuccess) onSuccess();
      },
      onError,
    },
  );
};

export default useUserRemove;

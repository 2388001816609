import React from 'react';
import PropTypes from 'prop-types';

function ErrorMessage({ children, modifiers }) {
  const classList = ['ErrorMessage'];
  classList.push(...modifiers.map(m => (m.length ? `ErrorMessage--${m}` : '')));

  return <div className={classList.join(' ')}>{children}</div>;
}

ErrorMessage.propTypes = {
  children: PropTypes.node.isRequired,
  modifiers: PropTypes.arrayOf(PropTypes.string),
};

ErrorMessage.defaultProps = {
  modifiers: [],
};

export default ErrorMessage;

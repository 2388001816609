import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

function Link({ button, modifiers, to, children }) {
  const classList = ['Link'];
  if (button) {
    classList.push('Link--button');
  }
  classList.push(...modifiers.map(m => `Link--${m}`));

  return (
    <RouterLink to={to} className={classList.join(' ')}>
      {children}
    </RouterLink>
  );
}

Link.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node,
  button: PropTypes.bool,
  modifiers: PropTypes.arrayOf(PropTypes.string),
};

Link.defaultProps = {
  children: '',
  button: false,
  modifiers: [],
};

export default Link;

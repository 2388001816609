import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import moment from 'moment/moment';
import { useConfig } from '@hummingbirdtechgroup/wings-config';
import Page, { Title } from '../../components/Page/index';
import Breadcrumbs from '../../components/Breadcrumbs';
import Table, { Col, Row, TableBody } from '../../components/Table/index';
import CompletenessIcon from '../../components/CompletenessIcon';
import { dateFormat } from '../../utils/lib';
import { useSatelliteListQuery } from '../../services/survey';
import FieldSurveyButtonGroup from './FieldSurveyButtonGroup';

const UAV = 'UAV';
const SAT = 'SAT';

function getSurveyLink(freshUrl, survey, season, farmId) {
  let url = `${freshUrl}farm/${farmId}/field/${survey.field_id}/?surveyId=${survey.id}`;
  if (season) url += `&seasonId=${season.id}`;
  return url;
}

function ViewSurveyDetails({ survey, farm, seasons, field, flightId }) {
  const isUAV = survey.source_type === UAV;
  const { data: satelliteList } = useSatelliteListQuery();
  const config = useConfig();

  const selectedSeason = seasons.find(season =>
    moment(survey.date).isBetween(season.startDate, season.endDate),
  );

  const satelliteName = get(
    satelliteList?.find(sat => sat.id === survey.satellite_id),
    'name',
    SAT,
  );

  return (
    <Page name="view-survey-data-core">
      <Breadcrumbs
        links={[
          {
            url:
              farm && field
                ? `/surveys?farmId=${farm.id}&fieldId=${field.id}`
                : '/surveys',
            label: 'Surveys',
          },
          {
            url: `/surveys/${survey.id}`,
            label: survey.id,
          },
        ]}
      />
      <FieldSurveyButtonGroup surveyId={survey.id} enabled={survey.enabled} />
      <Title>Survey Details</Title>
      <Table>
        {
          <TableBody>
            <Row>
              <Col width="50%" header>
                Survey ID
              </Col>
              <Col>
                {farm && (
                  <a
                    className="Link"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={getSurveyLink(
                      config.freshUrl,
                      survey,
                      selectedSeason,
                      farm.id,
                    )}
                  >
                    {survey.id}
                  </a>
                )}
              </Col>
            </Row>
            <Row>
              <Col width="50%" header>
                Farm
              </Col>
              <Col>{farm?.name}</Col>
            </Row>
            <Row>
              <Col width="50%" header>
                Field
              </Col>
              <Col>{field?.name}</Col>
            </Row>
            <Row>
              <Col width="50%" header>
                Flight date
              </Col>
              <Col>{moment(survey.captured_at).format(dateFormat)}</Col>
            </Row>
            <Row>
              <Col width="50%" header>
                Source type:
              </Col>
              <Col>{isUAV ? UAV : satelliteName}</Col>
            </Row>
            <Row>
              <Col width="50%" header>
                In platform
              </Col>
              <Col>
                <CompletenessIcon complete={survey.enabled} />
              </Col>
            </Row>

            {isUAV && (
              <Row>
                <Col width="50%" header>
                  Flight ID
                </Col>
                <Col>
                  <a
                    className="Link"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${config.uploaderUrl}report/${flightId}`}
                  >
                    {flightId}
                  </a>
                </Col>
              </Row>
            )}
          </TableBody>
        }
      </Table>
    </Page>
  );
}

ViewSurveyDetails.propTypes = {
  seasons: PropTypes.array.isRequired,
  farm: PropTypes.object,
  field: PropTypes.object,
  flightId: PropTypes.string,
  survey: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ViewSurveyDetails;

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Page, { Title } from '../../components/Page';
import Breadcrumbs from '../../components/Breadcrumbs';
import FarmForm from './FarmForm';
import ButtonGroup from '../../components/ButtonGroup';
import Button from '../../components/Button';
import Link from '../../components/Link';
import ErrorMessage from '../../components/ErrorMessage';
import useCreateFarm from '../../services/farms/useCreateFarm';
import { GET_USER_FARMS } from './FarmsList';

function CreateFarm() {
  const history = useHistory();
  const [state, setState] = useState({
    data: {
      name: '',
      region: '',
      clientId: '',
      countryCode: '',
    },
  });

  const [createFarm, { loading, error }] = useCreateFarm({
    onCompleted: ({ createFarm: { farm } }) =>
      history.push(`/farms/${farm.id}`),
    refetchQueries: [{ query: GET_USER_FARMS }],
  });

  const onSubmit = async data => {
    if (loading) {
      return;
    }

    const newState = { ...state, data };
    createFarm({ variables: { createFarmInput: data } });
    setState(newState);
  };

  return (
    <Page name="create-farm">
      <Breadcrumbs
        links={[
          {
            url: `/farms`,
            label: 'Farms',
          },
          {
            url: '/farms/create',
            label: 'Create farm',
          },
        ]}
      />

      <Title>Create farm</Title>

      <FarmForm
        data={state.data}
        error={
          error && (
            <ErrorMessage>
              There was an error creating the farm, please check the data
              entered and try again.
            </ErrorMessage>
          )
        }
        footer={
          <ButtonGroup>
            <Button>Create farm</Button>
            <Link to="/farms" button>
              Cancel
            </Link>
          </ButtonGroup>
        }
        onSubmit={onSubmit}
      />
    </Page>
  );
}

export default CreateFarm;

import React from 'react';
import PropTypes from 'prop-types';
import ButtonGroup from '../ButtonGroup';
import Select from '../Select';

function Navigation(props) {
  const { itemPerPage, page, amount, onChangePage } = props;
  const count = Math.ceil(amount / itemPerPage);
  const pages = [];
  const startFrom = itemPerPage * (page - 1) + 1;
  const endOn = itemPerPage * page > amount ? amount : itemPerPage * page;
  let counter = `${startFrom} - ${endOn} / ${amount}`;
  for (let i = 1; i <= count; i += 1) {
    pages.push({ key: `${i}`, label: `${i}` });
  }
  if (amount !== 0) {
    pages.push({ key: 'All', label: 'All' });
  } else {
    counter = 0;
  }
  if (page === 'All') {
    counter = 'All';
  }

  return (
    <div>
      <ButtonGroup modifiers={['center']}>
        <Select
          modifiers={['navigation']}
          name="page"
          value={page.toString()}
          options={pages}
          onChange={e => onChangePage(e.target.value)}
        />
        <ButtonGroup modifiers={['floated-right']}>{counter}</ButtonGroup>
      </ButtonGroup>
    </div>
  );
}

Navigation.propTypes = {
  itemPerPage: PropTypes.number.isRequired,
  page: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  amount: PropTypes.number,
  onChangePage: PropTypes.func.isRequired,
};

Navigation.defaultProps = {
  amount: 0,
};

export default Navigation;

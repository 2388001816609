import { useQuery } from 'react-query';
import { useConfig } from '@hummingbirdtechgroup/wings-config';
import { get } from '@hummingbirdtechgroup/wings-request';

const useSatelliteListQuery = (options = {}) => {
  const config = useConfig();

  return useQuery(
    ['satellite-list'],
    () => get(config.apiUrl, '/v1/satellites').then(res => res?.satellites),
    {
      ...options,
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  );
};

export default useSatelliteListQuery;

import React from 'react';
import PropTypes from 'prop-types';

function Form({ type, children, ...props }) {
  return (
    <form {...props} className={`Form Form--${type}`}>
      {children}
    </form>
  );
}

Form.propTypes = {
  type: PropTypes.string.isRequired,
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
};

export default Form;

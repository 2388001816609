import React from 'react';
import PropTypes from 'prop-types';

function Checkbox(props) {
  const id = props.id || props.name + props.value;

  return (
    <label className="Checkbox" key={id} htmlFor={id}>
      <input
        className="Checkbox__Control"
        name={props.name}
        {...props}
        id={id}
        type="checkbox"
      />
      <span className="Checkbox__Label">{props.label}</span>
    </label>
  );
}

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  id: PropTypes.string,
};

Checkbox.defaultProps = {
  id: undefined,
};

export default Checkbox;

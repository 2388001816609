import { useQuery } from 'react-query';
import { useConfig } from '@hummingbirdtechgroup/wings-config';
import { get } from '@hummingbirdtechgroup/wings-request';

const useSurveyQuery = (surveyId, options = {}) => {
  const config = useConfig();
  return useQuery(
    ['survey', surveyId],
    () => get(config.apiUrl, `/v1/surveys/${surveyId}`),
    options,
  );
};

export default useSurveyQuery;

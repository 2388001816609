import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Select extends Component {
  constructor(props) {
    super(props);

    this.onInput = this.onInput.bind(this);
    this.onInvalid = this.onInvalid.bind(this);
  }

  onInput(e) {
    if (!this.props.customValidity) {
      return;
    }

    e.target.setCustomValidity('');
  }

  onInvalid(e) {
    if (!this.props.customValidity) {
      return;
    }

    e.target.setCustomValidity(this.props.customValidity);
  }

  render() {
    const options = this.props.options.map(({ key, label }) => (
      <option value={key} key={key}>
        {label}
      </option>
    ));
    const { customValidity, modifiers, ...attrs } = { ...this.props };
    const classList = ['Select'];
    classList.push(...modifiers.map(m => (m.length ? `Select--${m}` : '')));
    delete attrs.options;
    delete attrs.placeholder;
    delete attrs.nullable;

    let placeholderOption = '';
    if (!this.props.value || this.props.nullable) {
      placeholderOption = (
        <option value="" disabled={!this.props.nullable}>
          {this.props.placeholder}
        </option>
      );
    }

    return (
      <select
        className={classList.join(' ')}
        id={this.props.name}
        data-validity={customValidity}
        {...attrs}
        onInput={this.onInput}
        onInvalid={this.onInvalid}
      >
        {placeholderOption}
        {options}
      </select>
    );
  }
}

Select.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  nullable: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  customValidity: PropTypes.string,
};

Select.defaultProps = {
  nullable: false,
  placeholder: '',
  modifiers: [],
  value: '',
  customValidity: undefined,
};

export default Select;

import { useQuery } from 'react-query';
import { useConfig } from '@hummingbirdtechgroup/wings-config';
import { get } from '@hummingbirdtechgroup/wings-request';

export const PRODUCTS_BY_SURVEY_KEY = 'productsBySurvey';

const useProductsBySurvey = (surveyId, options = {}) => {
  const config = useConfig();
  return useQuery(
    [PRODUCTS_BY_SURVEY_KEY, surveyId],
    () =>
      get(config.apiUrl, '/v1/products', {
        classes: 'analyses',
        survey_id: surveyId,
        only_enabled: 0,
      }).then(response => response?.products || []),
    options,
  );
};

export default useProductsBySurvey;

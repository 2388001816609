import { useApolloClient } from '@apollo/client';
import { useEffect, useState } from 'react';
import { makeMultipleFieldsQuery } from './farmGqlQueries';

const useMultipleFieldsQuery = (fieldIds = []) => {
  const client = useApolloClient();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (fieldIds.length) {
      (async () => {
        const query = makeMultipleFieldsQuery(fieldIds);
        try {
          setLoading(true);
          const results = await client.query({ query });
          if (typeof results.data === 'object') {
            setData(Object.values(results.data).filter(Boolean));
          }
          setLoading(false);
        } catch (err) {
          setError(err);
          setLoading(false);
        }
      })();
    }
  }, [fieldIds.join('')]);

  return { data, loading, error };
};

export default useMultipleFieldsQuery;

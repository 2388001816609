import { useQueries } from 'react-query';
import { get } from '@hummingbirdtechgroup/wings-request';
import { useConfig } from '@hummingbirdtechgroup/wings-config';

const combineQueryData = queries =>
  queries.reduce((acc, query) => {
    const { surveys = [] } = query?.data;
    return [...acc, ...surveys];
  }, []);

const useSurveysByFields = ({ fieldIds = [], dateFrom, dateTo }) => {
  const config = useConfig();

  const queries = useQueries(
    fieldIds.map(fieldId => ({
      queryKey: ['surveys', `field-${fieldId}`],
      queryFn: () =>
        get(
          config.apiUrl,
          `/v1/surveys/?field_id=${fieldId}&start_date=${dateFrom}&end_date=${dateTo}`,
        ),
      options: { enabled: fieldId },
    })),
  );

  const isLoading = !!queries.find(query => query.isLoading);

  return {
    data: isLoading ? [] : combineQueryData(queries),
    isLoading,
  };
};

export default useSurveysByFields;

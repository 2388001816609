import { useMutation, useQueryClient } from 'react-query';
import { request } from '@hummingbirdtechgroup/wings-request';
import { useConfig } from '@hummingbirdtechgroup/wings-config';

const useRemoveUserFromClient = clientId => {
  const config = useConfig();
  const queryClient = useQueryClient();

  return useMutation(
    user =>
      request(
        'DELETE',
        config.apiUrl,
        `/v1/users/${user.uid}/roles/${clientId}`,
      ),
    {
      onSuccess: (_, variables) => {
        const users = queryClient.getQueryData('users') || [];
        queryClient.setQueryData(
          'users',
          users.map(user => {
            if (user.uid === variables.uid) {
              return {
                ...user,
                clients: user.clients.filter(
                  ({ client_uid }) => client_uid !== clientId,
                ),
              };
            }
            return user;
          }),
        );
      },
    },
  );
};

export default useRemoveUserFromClient;

import { gql } from '@apollo/client';

export const GET_FARM = gql`
  query FarmFields($farmId: ID!) {
    farm(id: $farmId) {
      fields {
        id
        name
      }
    }
  }
`;

export const makeMultipleFieldsQuery = fieldIds => gql`
query Field {
  ${fieldIds.map(
    fieldId => `
    field${fieldId}: field(id: "${fieldId}") {
      id
      name
      farm {
        id
        name
      }
      baseZones {
        area
        id
        zoneCroppings {
          id
          variety {
            id
            name
          }
        }
      }
    }
  `,
  )}
  }
`;

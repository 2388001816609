import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ScrollableWrapper extends Component {
  constructor(props) {
    super(props);
    this.element = null;
    this.state = {
      classList: 'Scrollable__Wrapper',
      styles: this.getStyles(),
    };
    this.dimensionLookup = {
      width: 'offsetWidth',
      height: 'offsetHeight',
    };
  }

  componentDidMount() {
    const classList = this.getClasses();
    this.setClasses({ classList });
  }

  getClasses() {
    return this.props.overflow.map(d => {
      const dimension = this.dimensionLookup[d];
      if (!d || !dimension) {
        return '';
      }

      return `Scrollable__Wrapper--${d}`;
    });
  }

  getStyles() {
    const styles = {};
    this.props.overflow.forEach(d => {
      if (d && this.props[d]) {
        styles[d] = `${this.props[d]}px`;
      }
    });
    return styles;
  }

  setClasses({ classList }) {
    this.setState({
      ...this.state,
      classList: [this.state.classList, ...classList].join(' '),
    });
  }

  render() {
    return (
      <div
        ref={r => {
          this.element = r;
        }}
        className={this.state.classList}
        style={this.state.styles}
      >
        {this.props.children}
      </div>
    );
  }
}

ScrollableWrapper.propTypes = {
  children: PropTypes.node,
  overflow: PropTypes.arrayOf(PropTypes.string),
};

ScrollableWrapper.defaultProps = {
  children: '',
  overflow: '',
  width: '',
  height: '',
};

export default ScrollableWrapper;

import React from 'react';
import PropTypes from 'prop-types';

function CompletenessIcon({ complete }) {
  return (
    <span
      className={`CompletenessIcon CompletenessIcon--${
        complete ? 'complete' : 'incomplete'
      }`}
    />
  );
}

CompletenessIcon.propTypes = {
  complete: PropTypes.bool.isRequired,
};

export default CompletenessIcon;

import React from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { omit } from 'lodash';
import { useHistory } from 'react-router-dom';
import Page, { Title } from '../../components/Page';
import Breadcrumbs from '../../components/Breadcrumbs';
import FarmForm from './FarmForm';
import ButtonGroup from '../../components/ButtonGroup';
import Button from '../../components/Button';
import Link from '../../components/Link';
import ErrorMessage from '../../components/ErrorMessage';
import useFarm, { GET_FARM } from '../../services/farms/useFarm';
import useUpdateFarm from '../../services/farms/useUpdateFarm';

function EditFarm({ match }) {
  const history = useHistory();
  const farmId = match.params.id;

  const { data: farmData, loading } = useFarm(farmId);
  const farm = { ...farmData?.farm };
  if (farm.clientId === null) delete farm.clientId;

  const [updateFarm, { loading: updating, error }] = useUpdateFarm({
    onCompleted: () => history.push(`/farms/${farmId}`),
    refetchQueries: [{ query: GET_FARM, variables: { farmId } }],
  });

  const onSubmit = data => {
    if (updating) {
      return;
    }
    updateFarm({
      variables: {
        updateFarmInput: {
          id: farmId,
          ...omit(data, '__typename'),
        },
      },
    });
  };

  return (
    <Page name="create-farm" loading={loading}>
      <Breadcrumbs
        links={[
          {
            url: `/farms`,
            label: 'Farms',
          },
          {
            url: '/farms/create',
            label: 'Create farm',
          },
        ]}
      />

      <Title>Create farm</Title>

      <FarmForm
        data={farm}
        error={
          error && (
            <ErrorMessage>
              There was an error updating the farm, please check the data
              entered and try again.
            </ErrorMessage>
          )
        }
        footer={
          <ButtonGroup>
            <Button>Update farm</Button>
            <Link to={`/farms/${farmId}`} button>
              Cancel
            </Link>
          </ButtonGroup>
        }
        onSubmit={onSubmit}
      />
    </Page>
  );
}

EditFarm.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
};

export default EditFarm;

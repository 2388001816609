import moment from 'moment';
import { round } from 'lodash';
import { dateFormat } from '../../utils/lib';
import formatPercentage from '../Survey/formatPercentage';
import { exportToCsv } from '../../utils/exportToCsv';

const formatData = data =>
  data.map(
    ({
      id,
      capturedAt,
      fieldName,
      farmName,
      variety,
      fieldArea,
      percentageCovered,
      billingArea,
    }) => ({
      farmName,
      fieldName,
      id,
      variety,
      capturedAt: moment(capturedAt).format(dateFormat),
      fieldArea: fieldArea && `${round(fieldArea, 2)}ha`,
      percentageCovered: formatPercentage(percentageCovered) || '',
      billingArea: billingArea ? `${round(billingArea, 2)}ha` : '',
    }),
  );

export default function exportSurveys(tableData) {
  const formattedData = formatData(tableData);
  exportToCsv('surveys-data', formattedData);
}

import React from 'react';
import { Switch, Route } from 'react-router-dom';
import {
  useAuth,
  LoginRedirect,
  LogoutRedirect,
  PrivateRoute,
} from '@hummingbirdtechgroup/wings-auth';
import routes from './routes';
import SidebarLayout from './components/SidebarLayout/SidebarLayout';

function AppRoutes() {
  const { user } = useAuth();

  return (
    <Switch>
      {Object.keys(routes).map(route => {
        const Component = routes[route];
        return (
          <PrivateRoute
            key={route}
            path={route}
            exact
            render={props => (
              <SidebarLayout>
                <Component user={user} {...props} />
              </SidebarLayout>
            )}
          />
        );
      })}
      <Route exact path="/logout">
        <LogoutRedirect />
      </Route>
      <Route path="/login">
        <LoginRedirect />
      </Route>
    </Switch>
  );
}

export default AppRoutes;

import React, { useMemo } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import Page, { Title, Footer } from '../../components/Page';
import Link from '../../components/LinkComponent';
import Breadcrumbs from '../../components/Breadcrumbs';
import UsersTable from './UsersTable';
import useUsers from '../../services/users/useUsers';
import useClient from '../../services/clients/useClient';
import useRemoveUserFromClient from '../../services/users/useRemoveUserFromClient';
import useAddUserToClient from '../../services/users/useAddUserToClient';
import AddUserRow from './AddUserRow';
import RemoveUserRow from './RemoveUserRow';

const isUserBelongsToClient = (user, clientId) =>
  user.clients.findIndex(({ client_uid }) => clientId === client_uid) >= 0;

function ManageUsers({ match }) {
  const { clientId } = match.params;

  const { data: client = {} } = useClient(clientId);
  const { data: users = [], isLoading } = useUsers();

  const { mutate: addUser } = useAddUserToClient(clientId);
  const { mutate: removeUser } = useRemoveUserFromClient(clientId);

  const [unassignedUsers, clientUsers] = useMemo(() => {
    const unassigned = [];
    const assigned = [];

    users
      .filter(({ clients }) => clients)
      .forEach(user =>
        isUserBelongsToClient(user, clientId)
          ? assigned.push(user)
          : unassigned.push(user),
      );

    return [unassigned, assigned];
  }, [users]);

  return (
    <Page name="create-client" loading={isLoading}>
      <Breadcrumbs
        links={[
          {
            url: `/clients`,
            label: 'Clients',
          },
          {
            url: `/clients/${clientId}`,
            label: client.name,
          },
          {
            url: `/clients/${clientId}/users`,
            label: 'Users',
          },
        ]}
      />
      <Title>Manage Users</Title>
      <UsersTable title="User assigned to client" userList={clientUsers}>
        {user => (
          <RemoveUserRow
            key={`remove-user-${user.uid}`}
            user={user}
            clientRole={user.clients.find(
              ({ client_uid }) => client_uid === clientId,
            )}
            buttonAction={removeUser}
          />
        )}
      </UsersTable>
      <UsersTable
        title="User unassigned"
        clientId={clientId}
        userList={unassignedUsers}
      >
        {user => (
          <AddUserRow
            key={`add-user-${user.uid}`}
            user={user}
            buttonAction={addUser}
          />
        )}
      </UsersTable>
      <Footer>
        <Link to="/clients">&lt; Back</Link>
      </Footer>
    </Page>
  );
}

ManageUsers.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
};

export default ManageUsers;

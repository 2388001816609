import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import { get } from '@hummingbirdtechgroup/wings-request';
import { useConfig } from '@hummingbirdtechgroup/wings-config';
import Select from '../../components/Select';

function ClientSelector({ handleChange, value, ...rest }) {
  const config = useConfig();
  const { data, isLoading } = useQuery(['clients'], () =>
    get(config.apiUrl, '/v1/clients'),
  );

  const clients = data || [];

  const selectOptions = clients.map(({ uid, name }) => ({
    label: name,
    key: uid,
  }));

  return isLoading ? (
    'Loading clients...'
  ) : (
    <Select
      {...rest}
      options={selectOptions}
      onChange={e => handleChange(e)}
      value={value}
    />
  );
}

ClientSelector.propTypes = {
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.objectOf(PropTypes.func),
};

export default ClientSelector;

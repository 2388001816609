import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { intersection, uniq } from 'lodash';

import {
  useFlightsQuery,
  usePreProcessedImagesListQuery,
} from '../../services/survey';
import { GET_FARM } from './farmGqlQueries';
import useMultipleFieldsQuery from './useMultipleFieldsQuery';
import useSurveysByFields from '../../services/survey/useSurveysByFields';

const sortSurveys = (a, b) => new Date(b.captured_at) - new Date(a.captured_at);

const UAV_TYPE = 'UAV';

const formatSurveys = (
  surveysData = [],
  fields = [],
  preprocessedImagesData = [],
) => {
  const formattedSurveys = surveysData.sort(sortSurveys).map(survey => {
    const field = fields?.find(i => i.id === survey.field_id);
    const preprocessedImages = preprocessedImagesData.find(
      i => i.survey_id === survey.id,
    )?.preprocessed_images;
    const percentageCovered = preprocessedImages?.sort(
      (a, b) => b.percentage_covered - a.percentage_covered,
    )?.[0].percentage_covered;

    const fieldArea = field?.baseZones?.[0].area;

    const billingArea =
      percentageCovered > 0 &&
      fieldArea &&
      (fieldArea * percentageCovered) / 100;

    return {
      id: survey.id,
      capturedAt: survey.captured_at,
      fieldId: survey.field_id,
      sourceType: survey.source_type,
      satelliteName: survey.satellite?.name,
      fieldName: field?.name,
      farmName: field?.farm.name,
      variety: field?.baseZones?.[0].zoneCroppings?.[0]?.variety.name,
      fieldArea,
      percentageCovered,
      billingArea,
      enabled: survey.enabled,
    };
  });

  return formattedSurveys;
};

export default function useSurveysByFlights({
  farmId,
  fieldId,
  startDate,
  endDate,
  flightsQueryOptions,
  showBilling,
  showDisabled,
}) {
  const {
    data: flightsData = [],
    isLoading: flightsLoading,
    refetch,
  } = useFlightsQuery(
    {
      startDate,
      endDate,
    },
    flightsQueryOptions,
  );

  const { data: farmData } = useQuery(GET_FARM, {
    variables: { farmId },
    skip: !!fieldId || !farmId,
  });

  const farmFieldIds = farmId
    ? (farmData?.farm.fields || []).map(field => field.id)
    : null;
  const filedIdsFilter = fieldId ? [fieldId] : farmFieldIds;

  const fieldIds = uniq(
    flightsData?.reduce((acc, flight) => [...acc, ...flight.field_ids], []) ??
      [],
  );

  const filteredFieldIds = filedIdsFilter
    ? intersection(fieldIds, filedIdsFilter)
    : fieldIds;

  const { data: fieldsData } = useMultipleFieldsQuery(filteredFieldIds);

  const { data: surveysData, isLoading: surveysLoading } = useSurveysByFields({
    fieldIds: filteredFieldIds,
    dateFrom: startDate,
    dateTo: endDate,
  });

  const filteredSurveys = useMemo(
    () =>
      surveysData.filter(
        survey =>
          survey.source_type === UAV_TYPE && (showDisabled || survey.enabled),
      ),
    [showDisabled, surveysData],
  );

  const surveyIds = filteredSurveys.map(survey => survey.id);

  const {
    data: preprocessedImagesData,
    isLoading: isBillingLoading,
  } = usePreProcessedImagesListQuery(surveyIds, { enabled: showBilling });

  const formatted = useMemo(
    () => formatSurveys(filteredSurveys, fieldsData, preprocessedImagesData),
    [filteredSurveys, fieldsData, preprocessedImagesData],
  );
  return {
    data: formatted,
    isLoading: flightsLoading || surveysLoading,
    isBillingLoading,
    refetch,
  };
}

import React from 'react';
import { round } from 'lodash';
import moment from 'moment';
import Link from '../../components/LinkComponent';
import { Col, Row } from '../../components/Table/index';
import { dateFormat } from '../../utils/lib';
import formatPercentage from '../Survey/formatPercentage';

function SurveyRow({ survey, showBilling }) {
  const {
    id,
    farmName,
    fieldName,
    variety,
    capturedAt,
    fieldArea,
    percentageCovered,
    billingArea,
  } = survey;
  const classList = percentageCovered < 60 ? ['lowerCoverage'] : [];

  return (
    <Row key={id} modifiers={classList}>
      <Col>{farmName}</Col>
      <Col>{fieldName}</Col>
      <Col modifier="surveyId">
        <Link to={`/surveys/${id}`}>{id}</Link>
      </Col>
      <Col>{variety}</Col>
      <Col>{moment(capturedAt).format(dateFormat)}</Col>
      <Col>{fieldArea && `${round(fieldArea, 2)}ha`}</Col>
      {showBilling && (
        <>
          <Col>{percentageCovered && formatPercentage(percentageCovered)}</Col>
          <Col>{billingArea && `${round(billingArea, 2)}ha`}</Col>
        </>
      )}
    </Row>
  );
}

export default SurveyRow;

import React from 'react';
import PropTypes from 'prop-types';
import Page, { Title } from '../../components/Page/index';
import Table, {
  Col,
  TableBody,
  TableHeader,
} from '../../components/Table/index';
import SurveyRow from './SurveyRow';

function SurveysTable({ surveys = [], loading, showBilling, controls }) {
  const filteredSurveys = surveys;
  return (
    <Page name="view-field-surveys" loading={loading}>
      <Title>Field surveys</Title>

      {controls}

      <Table align="center">
        <TableHeader>
          <Col header>Farm</Col>
          <Col header>Field</Col>
          <Col header>Survey Id</Col>
          <Col header>Variety</Col>
          <Col header>Date</Col>
          <Col header>Field area</Col>
          {showBilling && (
            <>
              <Col header>% Flown</Col>
              <Col header>Billing area</Col>
            </>
          )}
        </TableHeader>

        <TableBody>
          {surveys.length > 0 &&
            filteredSurveys.map(survey => (
              <SurveyRow
                key={survey.id}
                survey={survey}
                showBilling={showBilling}
              />
            ))}
        </TableBody>
      </Table>
    </Page>
  );
}

SurveysTable.propTypes = {
  surveys: PropTypes.array,
  loading: PropTypes.bool,
};

export default SurveysTable;

import React from 'react';
import PropTypes from 'prop-types';
import Select from './Select';

const selectOptions = [
  { value: 'SUPERUSER', label: 'SUPERUSER' },
  { value: 'NORMAL_USER', label: 'NORMAL_USER' },
  { value: 'PILOT', label: 'PILOT' },
];

function RoleSelect({ handleChange, value, ...rest }) {
  return (
    <Select
      {...rest}
      options={selectOptions}
      onChange={e => handleChange(e)}
      value={value}
    />
  );
}

RoleSelect.propTypes = {
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.objectOf(PropTypes.func),
};

export default RoleSelect;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import { get } from 'lodash';
import Page, { Title, Content } from '../../components/Page/index';
import ButtonGroup from '../../components/ButtonGroup';
import Button from '../../components/Button';
import Table, { Col, Row, TableBody } from '../../components/Table/index';
import { dateFormatShort } from '../../utils/lib';
import ErrorMessage from '../../components/ErrorMessage';
import formatPercentage from './formatPercentage';

const activeButtonModifer = ['small'];
const inactiveButtonModifier = ['small', 'simple'];
function ViewSurveyDetailsSpectrum({ orthos }) {
  const [activeSpectrum, setActiveSpectrum] = useState(
    get(orthos, '[0].image_type'),
  );

  const flightData = orthos.find(ortho => ortho.image_type === activeSpectrum);

  if (!orthos.length) {
    return <ErrorMessage>Flight data unavailable</ErrorMessage>;
  }

  return (
    <Page name="view-survey-data-spectrum">
      <Title>Survey Flight Details</Title>

      <Content>
        <ButtonGroup modifiers={['center']}>
          {orthos.map(ortho => (
            <Button
              modifiers={
                activeSpectrum === ortho.image_type
                  ? activeButtonModifer
                  : inactiveButtonModifier
              }
              onClick={() => setActiveSpectrum(ortho.image_type)}
            >
              {ortho.image_type}
            </Button>
          ))}
        </ButtonGroup>

        {flightData ? (
          <>
            <Table>
              <TableBody>
                <Row>
                  <Col width="50%" header>
                    Date
                  </Col>
                  <Col>
                    {moment(flightData.median_date).format(dateFormatShort)}
                  </Col>
                </Row>
                <Row>
                  <Col width="50%" header>
                    Sensor Model
                  </Col>
                  <Col>{get(flightData, 'sensor.model', '-')}</Col>
                </Row>
                <Row>
                  <Col width="50%" header>
                    Sensor Make
                  </Col>
                  <Col>{get(flightData, 'sensor.make', '-')}</Col>
                </Row>
                <Row>
                  <Col width="50%" header>
                    Percentage Covered
                  </Col>
                  <Col>{formatPercentage(flightData.percentage_covered)}</Col>
                </Row>
                <Row>
                  <Col width="50%" header>
                    Total Images
                  </Col>
                  <Col>{flightData.total}</Col>
                </Row>
              </TableBody>
            </Table>
          </>
        ) : (
          <ErrorMessage>Flight has no {activeSpectrum} data</ErrorMessage>
        )}
      </Content>
    </Page>
  );
}

ViewSurveyDetailsSpectrum.propTypes = {
  orthos: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ViewSurveyDetailsSpectrum;

import { useMutation } from 'react-query';
import { patch } from '@hummingbirdtechgroup/wings-request';
import { omit } from 'lodash';
import { useConfig } from '@hummingbirdtechgroup/wings-config';

function useUserUpdate(userId, options) {
  const config = useConfig();

  return useMutation(
    data =>
      patch(
        config.apiUrl,
        `/v1/users/${userId}`,
        omit(
          {
            ...data,
            role: data.staff ? undefined : data.clients[0],
          },
          'uid',
          'clients',
        ),
      ),
    options,
  );
}

export default useUserUpdate;

import { gql, useMutation } from '@apollo/client';

const DELETE_FARM = gql`
  mutation DeleteFarm($deleteFarmId: ID!) {
    deleteFarm(id: $deleteFarmId) {
      ok
    }
  }
`;

function useDeleteFarm(options) {
  return useMutation(DELETE_FARM, options);
}

export default useDeleteFarm;

import { useQueries } from 'react-query';
import { get } from '@hummingbirdtechgroup/wings-request';
import { useConfig } from '@hummingbirdtechgroup/wings-config';
import { useMemo } from 'react';

/**
 * Get a list of preprocess images by survey_id, flight_id or sensor_id
 * @param {string} id - A survey, flight or sensor Id
 * @param {string} key - The key to query by, one of `survey_id` | `flight_id` | `sensor_id`
 * @param {*} options - react-query useQuery options
 * @returns Promise
 */
const usePreProcessedImagesListQuery = (ids = [], options = {}) => {
  const config = useConfig();

  const queries = useQueries(
    ids.map(id => ({
      queryKey: ['preprocessed-images', id],
      queryFn: () =>
        get(config.apiUrl, `/v1/preprocessed-images?survey_id=${id}`),
      ...options,
    })),
  );
  const isLoading = Boolean(queries.find(query => query.isLoading));
  const data = useMemo(() => {
    if (!isLoading && options.enabled) {
      return queries.map(query => ({
        ...query.data,
        survey_id: query.data?.preprocessed_images?.[0]?.survey_id,
      }));
    }
    return undefined;
  }, [isLoading, queries]);
  return { data, isLoading };
};

export default usePreProcessedImagesListQuery;

import React, { useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import Table, {
  Col,
  Row,
  TableBody,
  TableHeader,
} from '../../components/Table';
import Page, { ScrollableWrapper, Title } from '../../components/Page';
import TextInput from '../../components/TextInput';
import InputGroup from '../../components/InputGroup';
import ButtonGroup from '../../components/ButtonGroup';
import Link from '../../components/Link';

export const GET_USER_FARMS = gql`
  query UserFarms($userId: Int) {
    farms(userId: $userId) {
      id
      name
    }
  }
`;

function FarmsList({ userId }) {
  const { data: farmsData, loading } = useQuery(GET_USER_FARMS, {
    variables: { userId },
  });

  const [searched, setSearch] = useState('');

  const farmOptions = farmsData?.farms
    .map(({ name, id }) => ({ name, id }))
    .filter(({ name }) => name.includes(searched));

  return (
    <Page name="farms" loading={loading}>
      <Title modifiers={['floated-left']}>Farms</Title>
      <ButtonGroup modifiers={['search']}>
        <InputGroup>
          <TextInput
            type="text"
            name="name"
            placeholder="Search farms ..."
            value={searched}
            modifiers={['search']}
            required
            onChange={e => setSearch(e.target.value)}
          />
          <span className="input-group-search" />
        </InputGroup>
        <Link to="/farms/create" button>
          Create farm
        </Link>
      </ButtonGroup>
      <ScrollableWrapper overflow={['height']} width="100%">
        <Table>
          <TableHeader>
            <Col header>Farms Name</Col>
            <Col width="280" header>
              ID
            </Col>
          </TableHeader>

          <TableBody>
            {!loading &&
              farmOptions?.map(farm => (
                <Row key={farm.id}>
                  <Col>
                    <Link to={`/farms/${farm.id}`}>{farm.name}</Link>
                  </Col>
                  <Col>{farm.id}</Col>
                </Row>
              ))}
          </TableBody>
        </Table>
      </ScrollableWrapper>
    </Page>
  );
}

export default FarmsList;

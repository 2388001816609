import React from 'react';
import PropTypes from 'prop-types';

function Title({ modifiers, children }) {
  const classList = ['Page__Title'];
  classList.push(...modifiers.map(m => `Title--${m}`));

  return <h1 className={classList.join(' ')}>{children}</h1>;
}

Title.propTypes = {
  children: PropTypes.node.isRequired,
  modifiers: PropTypes.arrayOf(PropTypes.string),
};

Title.defaultProps = {
  children: '',
  modifiers: [],
};

export default Title;

import { gql, useQuery } from '@apollo/client';

export const GET_FARM = gql`
  query Farm($farmId: ID!) {
    farm(id: $farmId) {
      id
      name
      countryCode
      region
      clientId
    }
  }
`;

function useFarm(farmId, options) {
  return useQuery(GET_FARM, {
    ...options,
    variables: { farmId },
    skip: !farmId,
  });
}

export default useFarm;

import { useQuery } from 'react-query';
import { useConfig } from '@hummingbirdtechgroup/wings-config';
import { get } from '@hummingbirdtechgroup/wings-request';

const useClients = (options = {}) => {
  const config = useConfig();
  return useQuery('clients', () => get(config.apiUrl, '/v1/clients'), options);
};

export default useClients;

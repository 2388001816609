import React from 'react';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import Page, { Title } from '../../components/Page/index';
import Table, {
  Col,
  Row,
  TableBody,
  TableHeader,
} from '../../components/Table/index';
import formatPercentage from './formatPercentage';

function ViewSurveyOrthomosaics({ orthos }) {
  return (
    <Page name="view-orthomosaics">
      <Title>Survey Preprocessed Images</Title>

      <Table>
        <TableHeader>
          <Col width="33%" header>
            Flight ID
          </Col>
          <Col header>Preprocessed Type</Col>
          <Col header>Median Altitude</Col>
          <Col header>Median Date</Col>
          <Col header>Percentage Covered</Col>
          <Col header>Total</Col>
        </TableHeader>
        <TableBody>
          {orthos.map(
            ({
              image_type,
              flight_id,
              median_altitude,
              median_date,
              percentage_covered,
              total,
            }) => (
              <Row key={flight_id}>
                <Col>{flight_id}</Col>
                <Col>{image_type}</Col>
                <Col>{median_altitude}</Col>
                <Col>{moment(median_date).format('YY/MM/DD ddd HH:mm:ss')}</Col>
                <Col>{formatPercentage(percentage_covered)}</Col>
                <Col>{total}</Col>
              </Row>
            ),
          )}
        </TableBody>
      </Table>
    </Page>
  );
}

ViewSurveyOrthomosaics.propTypes = {
  orthos: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ViewSurveyOrthomosaics;

import { useMutation, useQueryClient } from 'react-query';
import { useConfig } from '@hummingbirdtechgroup/wings-config';
import { patch } from '@hummingbirdtechgroup/wings-request';
import { PRODUCTS_BY_SURVEY_KEY } from './useProductsBySurvey';

const useProductMutation = (productId, surveyId) => {
  const queryClient = useQueryClient();
  const config = useConfig();

  return useMutation(
    ['mutateProduct', productId],
    data => patch(config.apiUrl, `/v1/products/${productId}`, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([PRODUCTS_BY_SURVEY_KEY, surveyId]);
      },
    },
  );
};

export default useProductMutation;

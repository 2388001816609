import React from 'react';
import PropTypes from 'prop-types';

function Button({ children, type, modifiers, ...props }) {
  const classList = ['Button'];
  classList.push(...modifiers.map(m => (m.length ? `Button--${m}` : '')));

  return (
    <button className={classList.join(' ')} type={type} {...props}>
      {children}
    </button>
  );
}

Button.propTypes = {
  children: PropTypes.string.isRequired,
  type: PropTypes.string,
  modifiers: PropTypes.arrayOf(PropTypes.string),
};

Button.defaultProps = {
  type: 'submit',
  modifiers: [],
};

export default Button;

import fetch from 'cross-fetch';
import { InMemoryCache, createHttpLink, ApolloLink } from '@apollo/client';
import authHandlerLink from './authHandler';

const customFetch = (uri, options) => {
  const { operationName } = JSON.parse(options.body);
  return fetch(`${uri}/v1/graphql?operation_name=${operationName}`, options);
};

const httpLink = gqlGatewayUrl =>
  createHttpLink({
    uri: `${gqlGatewayUrl}`,
    fetch: customFetch,
  });

const cache = new InMemoryCache();

const apolloConfig = gqlGatewayUrl => ({
  link: ApolloLink.from([authHandlerLink, httpLink(gqlGatewayUrl)]),
  cache,
  name: 'admin',
  version: process.env.APP_VERSION,
});

export default apolloConfig;

import React from 'react';
import { TableBody } from '../../components/Table';
import SurveyAnalysesRow from './SurveyAnalysesRow';

const SurveyAnalysesBody = ({
  analyses,
  surveyId,
  isAnalysisLoading,
  selectAnalysis,
  selectedAnalyses,
}) => (
  <TableBody>
    {analyses.map(analysis => (
      <SurveyAnalysesRow
        analysis={analysis}
        surveyId={surveyId}
        checked={selectedAnalyses.includes(analysis.id)}
        isAnalysisLoading={isAnalysisLoading}
        selectAnalysis={selectAnalysis}
      />
    ))}
  </TableBody>
);

export default SurveyAnalysesBody;

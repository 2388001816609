import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from '../../components/Table';
import Form from '../../components/Form';
import RoleSelect from '../../components/RoleSelect';
import Button from '../../components/Button';

function AddUserRow({ user, buttonAction }) {
  const [role, setRole] = useState();

  const onSubmit = e => {
    e.preventDefault();
    buttonAction({ user, role });
  };

  return (
    <Row key={user.uid}>
      <Col>
        <Link to={`/users/${user.uid}`}>{user.name}</Link>
      </Col>
      <Col align="center">
        <Form type="submit" onSubmit={onSubmit} id={`form-${user.uid}`}>
          <RoleSelect
            placeholder="Select role"
            id={user.uid}
            value={role}
            handleChange={e => setRole(e.target.value)}
          />
        </Form>
      </Col>
      <Col>
        <Button
          modifiers={['small']}
          type="submit"
          form={`form-${user.uid}`}
          disabled={!role}
        >
          Add
        </Button>
      </Col>
    </Row>
  );
}
export default AddUserRow;

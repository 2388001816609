import React from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { useHistory } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import Page, { Title } from '../../components/Page';
import Breadcrumbs from '../../components/Breadcrumbs';
import UserForm from './UserForm';
import ButtonGroup from '../../components/ButtonGroup';
import Button from '../../components/Button';
import Link from '../../components/LinkComponent';
import ErrorMessage from '../../components/ErrorMessage';
import useUser from '../../services/users/useUser';
import useUserUpdate from '../../services/users/useUserUpdate';

function EditUser({ match }) {
  const userId = match.params.id;
  const history = useHistory();
  const queryClient = useQueryClient();

  const { data: userData, isLoading } = useUser(userId);
  const user = userData || {};

  const { mutate, isError } = useUserUpdate(userId, {
    onSuccess: data => {
      queryClient.setQueryData(['users', userId], data);
      history.push(`/users/${data.uid}`);
    },
  });

  return (
    <Page name="edit-user" loading={isLoading}>
      <Breadcrumbs
        links={[
          {
            url: `/users`,
            label: 'Users',
          },
          {
            url: `/users/${userId}`,
            label: user.name,
          },
          {
            url: `/users/${userId}/edit`,
            label: 'Edit',
          },
        ]}
      />
      <Title>Edit user</Title>

      <UserForm
        edit
        data={user}
        error={
          isError && (
            <ErrorMessage>
              There was an error updating the user, please check the data
              entered and try again.
            </ErrorMessage>
          )
        }
        footer={
          <ButtonGroup>
            <Button>Update user</Button>
            <Link to={`/users/${userId}`} button>
              Cancel
            </Link>
          </ButtonGroup>
        }
        onSubmit={data => mutate(data)}
      />
    </Page>
  );
}

EditUser.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
};

export default EditUser;

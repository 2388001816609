import React from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { useMutation, useQueryClient } from 'react-query';
import { patch } from '@hummingbirdtechgroup/wings-request';
import { useConfig } from '@hummingbirdtechgroup/wings-config';
import { useHistory } from 'react-router-dom';
import Page, { Title, Footer } from '../../components/Page';
import Link from '../../components/LinkComponent';
import ErrorMessage from '../../components/ErrorMessage';
import Breadcrumbs from '../../components/Breadcrumbs';
import useClient from '../../services/clients/useClient';
import ClientForm from './ClientForm.js';

function EditClient({ match }) {
  const history = useHistory();
  const config = useConfig();
  const queryClient = useQueryClient();
  const { clientId } = match.params;
  const { data = {}, isLoading } = useClient(clientId);

  const { mutate, isError } = useMutation(
    newData => patch(config.apiUrl, `/v1/clients/${clientId}`, newData),
    {
      onSuccess: newData => {
        queryClient.setQueryData(['clients', clientId], newData);
        history.push(`/clients/${clientId}`);
      },
    },
  );

  return (
    <Page name="create-client" loading={isLoading}>
      <Breadcrumbs
        links={[
          {
            url: `/clients`,
            label: 'Clients',
          },
          {
            url: '/clients/edit',
            label: 'Edit client',
          },
        ]}
      />
      <Title>Edit Client</Title>
      <ClientForm
        onSubmit={newData => mutate(newData)}
        error={
          isError && (
            <ErrorMessage>
              There was an error editing the client name, please check the data
              entered and try again.
            </ErrorMessage>
          )
        }
        clientData={data}
        buttonName="Edit"
      />
      <Footer>
        <Link to="/clients">&lt; Back</Link>
      </Footer>
    </Page>
  );
}

EditClient.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
};

export default EditClient;

import { ApolloLink } from '@apollo/client';
import { getToken } from '@hummingbirdtechgroup/wings-auth';

const authHandlerLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: `Bearer ${getToken()}`,
    },
  }));
  return forward(operation);
});

export default authHandlerLink;

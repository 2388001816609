import React from 'react';
import CompositePage from '../../components/CompositePage';
import ViewCropDetails from './ViewCropDetails';

function ViewCrop(props) {
  return (
    <CompositePage name="view-crop">
      <CompositePage name="left">
        <ViewCropDetails {...props} />
      </CompositePage>
    </CompositePage>
  );
}

export default ViewCrop;

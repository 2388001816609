/**
 * @param fileURL - string
 * @param fileName - string
 * @return void
 */

export default function fileDownloader(fileURL, fileName) {
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.href = fileURL;
  a.download = fileName;
  a.click();
  a.remove();
}

import { useQuery } from 'react-query';
import { useConfig } from '@hummingbirdtechgroup/wings-config';
import { get } from '@hummingbirdtechgroup/wings-request';

const useClient = (id, options = {}) => {
  const config = useConfig();
  return useQuery(
    ['clients', id],
    () => get(config.apiUrl, `/v1/clients/${id}`),
    options,
  );
};

export default useClient;

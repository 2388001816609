import React from 'react';
import PropTypes from 'prop-types';
import { Footer } from '../../components/Page';
import Form from '../../components/Form';
import FormRow from '../../components/FormRow';
import TextInput from '../../components/TextInput';
import Checkbox from '../../components/Checkbox';
import RoleSelect from '../../components/RoleSelect';
import ClientSelector from '../Clients/ClientSelector';

class UserForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        name: '',
        email: '',
        phone_number: '',
        staff: false,
        clients: [{ client_uid: '', role: '' }],
        ...props.data,
      },
    };
  }

  onFieldChange = e => {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });
  };

  onRoleChange = e => {
    const client = this.state.data.clients?.[0] || {};
    client.role = e.target.value;
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        clients: [client],
      },
    });
  };

  onClientChange = e => {
    const role = this.state.data.clients?.[0] || {};
    role.client_uid = e.target.value;
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        role,
      },
    });
  };

  onCheckboxChange = e => {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        [e.target.name]: e.target.checked,
      },
    });
  };

  onSubmit = e => {
    e.preventDefault();
    this.props.onSubmit(this.state.data);
  };

  render() {
    return (
      <Form type="user" onSubmit={this.onSubmit}>
        <FormRow label="Name" labelFor="name">
          <TextInput
            type="text"
            name="name"
            placeholder="name"
            value={this.state.data.name}
            required
            onChange={this.onFieldChange}
            customValidity="please enter a name"
          />
        </FormRow>

        <FormRow label="Email" labelFor="email">
          <TextInput
            type="email"
            name="email"
            placeholder="email address"
            value={this.state.data.email}
            required
            onChange={this.onFieldChange}
            customValidity="please enter an email address"
          />
        </FormRow>
        <FormRow label="tel" labelFor="phone_number">
          <TextInput
            type="tel"
            name="phone_number"
            placeholder="phone number"
            value={this.state.data.phone_number}
            pattern="^\+[1-9]\d{10,14}$"
            onChange={this.onFieldChange}
            customValidity="please enter an phone number e.g.: +12125551212"
          />
        </FormRow>

        <FormRow label="Staff" labelFor="staff">
          <Checkbox
            name="staff"
            label="Staff"
            value={this.state.data.staff}
            checked={this.state.data.staff}
            onChange={this.onCheckboxChange}
          />
        </FormRow>

        <FormRow label="Select Role" labelFor="role">
          <RoleSelect
            required={
              this.state.data.clients[0]?.client_uid || !this.state.data.staff
            }
            name="role"
            value={this.state.data.clients[0]?.role}
            handleChange={this.onRoleChange}
          />
        </FormRow>
        <FormRow label="Select Client" labelFor="client">
          <ClientSelector
            required={
              this.state.data.clients[0]?.role || !this.state.data.staff
            }
            name="client"
            value={this.state.data.clients[0]?.client_uid}
            handleChange={this.onClientChange}
          />
        </FormRow>

        {this.props.error}

        <Footer>{this.props.footer}</Footer>
      </Form>
    );
  }
}

UserForm.propTypes = {
  footer: PropTypes.node.isRequired,
  error: PropTypes.node.isRequired,
  onSubmit: PropTypes.objectOf(PropTypes.func),
  data: PropTypes.objectOf(PropTypes.any),
};

UserForm.defaultProps = {
  data: {},
  edit: false,
};

export default UserForm;

import { useMutation, useQueryClient } from 'react-query';
import { useConfig } from '@hummingbirdtechgroup/wings-config';
import { patch } from '@hummingbirdtechgroup/wings-request';

const useEditSurveyMutation = options => {
  const queryClient = useQueryClient();
  const config = useConfig();

  return useMutation(
    ({ id, ...rest }) => patch(config.apiUrl, `/v1/surveys/${id}`, rest),
    {
      onSuccess: (data, variables) =>
        queryClient.setQueryData(['survey', variables.id], data),
      ...options,
    },
  );
};

export default useEditSurveyMutation;

import React, { useState } from 'react';
import { uniqBy } from 'lodash';
import Page, { Title } from '../../components/Page';
import Table, { Col, TableHeader } from '../../components/Table';
import SurveyAnalysesBody from './SurveyAnalysesBody';
import { useProductsBySurvey } from '../../services/product';

const SurveyAnalysesTable = ({ surveyId }) => {
  const {
    data: products = [],
    isLoading: isAnalysisLoading,
  } = useProductsBySurvey(surveyId);

  const [selectedAnalyses, setSelectedAnalyses] = useState([]);

  const analyses = uniqBy(products, 'product_type_id');

  const selectAnalysis = analysisId => {
    const analysesLocation = selectedAnalyses.indexOf(analysisId);
    if (analysesLocation > -1) {
      setSelectedAnalyses([...selectedAnalyses.splice(analysesLocation, 1)]);
    } else {
      setSelectedAnalyses([...selectedAnalyses, analysisId]);
    }
  };

  return (
    <Page name="view-analyses">
      <Title>Survey Analyses</Title>

      <Table striped>
        <TableHeader>
          <Col header>&nbsp;</Col>
          <Col width="33%" header>
            Name
          </Col>
          <Col header>Started</Col>
          <Col header>Status</Col>
          <Col header>Report</Col>
          <Col header>Disable</Col>
        </TableHeader>
        <SurveyAnalysesBody
          analyses={analyses}
          surveyId={surveyId}
          isAnalysisLoading={isAnalysisLoading}
          selectAnalysis={selectAnalysis}
          selectedAnalyses={selectedAnalyses}
        />
      </Table>
      {analyses.length > 0 && (
        <p>
          The re run analysis feature has been disabled in order to tackle tech
          debt. If you wish to have an analysis re run please contact{' '}
          <a href="mailto:tech-support@hummingbirdtech.com">
            tech-support@hummingbirdtech.com
          </a>
        </p>
      )}
    </Page>
  );
};

export default SurveyAnalysesTable;

import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from '../../components/Table';
import Button from '../../components/Button';

function RemoveUserRow({ user, clientRole, buttonAction }) {
  return (
    <Row key={user.uid}>
      <Col>
        <Link to={`/users/${user.uid}`}>{user.name}</Link>
      </Col>
      <Col align="center">{clientRole?.role}</Col>
      <Col>
        <Button modifiers={['small']} onClick={() => buttonAction(user)}>
          Remove
        </Button>
      </Col>
    </Row>
  );
}

export default RemoveUserRow;

import React, { useEffect, useMemo, useState } from 'react';
import { get } from 'lodash';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import { useQuery } from '@apollo/client';
import Select from 'react-select';
import Button from '../../components/Button';
import ButtonGroup from '../../components/ButtonGroup';
import { useSearchParams } from '../../utils/routerHooks';
import { dateFormat } from '../../utils/lib';
import CompositePage from '../../components/CompositePage';
import Page from '../../components/Page';
import SurveysTable from './SurveysTable';
import Breadcrumbs from '../../components/Breadcrumbs';
import FarmsSelect from '../../components/FarmsSelect/FarmsSelect';
import useSurveysByFlights from './useSurveysByFlights';
import { GET_FARM } from './farmGqlQueries';
import exportSurveys from './exportSurveys';

function FieldSurveys() {
  const [{ fieldId, farmId }, setSearchParams] = useSearchParams();
  const [focusedInput, setFocusedInput] = useState();
  const [showBilling, setShowBilling] = useState(false);
  const [showDisabled, setShowDisabled] = useState(false);
  const [{ startDate, endDate }, setDateRange] = useState({
    startDate: moment().subtract(4, 'weeks'),
    endDate: moment(),
  });

  const {
    data: surveys,
    isLoading,
    isBillingLoading,
    refetch: loadSurveys,
  } = useSurveysByFlights({
    fieldId,
    farmId,
    startDate: moment(startDate).toISOString(true),
    endDate: moment(endDate).toISOString(true),
    flightsQueryOptions: { enabled: false },
    showBilling,
    showDisabled,
  });

  useEffect(() => {
    loadSurveys();
  }, []);

  const { data: farmData, loading: loadingFields } = useQuery(GET_FARM, {
    variables: { farmId },
    skip: !farmId,
  });

  const fields = get(farmData, 'farm.fields', []);
  const fieldOptions = useMemo(
    () => [
      { label: 'None', value: undefined },
      ...fields.map(field => ({
        label: field.name,
        value: field.id,
      })),
    ],
    [fields],
  );
  const fieldValue = fieldOptions.find(field => `${field.value}` === fieldId);

  const handleFarmSelect = option => setSearchParams({ farmId: option.value });

  const handleFieldSelect = option =>
    setSearchParams({ fieldId: option.value }, { merge: true });

  return (
    <CompositePage name="Field Surveys">
      <Page name="Field Surveys">
        <Breadcrumbs
          links={[
            {
              url: `/surveys`,
              label: 'Surveys',
            },
          ]}
        />
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyItems: 'space-between',
            padding: '8px',
          }}
        >
          <div style={{ width: '40%' }}>
            <FarmsSelect farmId={farmId} onChange={handleFarmSelect} />
            <Select
              className="FieldSurveys__FarmSelect"
              placeholder="Select a field"
              isLoading={loadingFields}
              value={fieldValue}
              options={fieldOptions}
              onChange={handleFieldSelect}
            />
          </div>

          <div
            style={{
              width: '60%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <DateRangePicker
              startDateId={'startDate'}
              endDateId={'endDate'}
              startDate={startDate}
              endDate={endDate}
              onDatesChange={dates => setDateRange(dates)}
              displayFormat={dateFormat}
              focusedInput={focusedInput}
              onFocusChange={setFocusedInput}
              isOutsideRange={() => false}
            />
            <Button onClick={() => loadSurveys()}>Find</Button>
          </div>
        </div>

        <SurveysTable
          surveys={surveys}
          loading={isLoading}
          showBilling={showBilling}
          controls={
            <>
              <ButtonGroup modifiers={['float-left']}>
                <Button
                  onClick={() => setShowDisabled(state => !state)}
                  modifiers={['small', 'simple']}
                >
                  {showDisabled ? 'Hide disabled' : 'Show disabled'}
                </Button>
              </ButtonGroup>
              <ButtonGroup>
                <Button
                  onClick={() => {
                    exportSurveys(surveys);
                  }}
                  modifiers={['small']}
                >
                  Export
                </Button>
                <Button
                  onClick={() => {
                    setShowBilling(true);
                  }}
                  modifiers={['small']}
                >
                  {isBillingLoading ? 'loading...' : 'Load Billing Data'}
                </Button>
              </ButtonGroup>
            </>
          }
        />
      </Page>
    </CompositePage>
  );
}

export default FieldSurveys;

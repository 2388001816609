import React, { useState } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { useConfig } from '@hummingbirdtechgroup/wings-config';
import { useHistory } from 'react-router-dom';
import { request } from '@hummingbirdtechgroup/wings-request';
import { useMutation } from 'react-query';
import Page, { Title, Footer } from '../../components/Page';
import Breadcrumbs from '../../components/Breadcrumbs';
import ButtonGroup from '../../components/ButtonGroup';
import Table, { Col, Row, TableBody } from '../../components/Table';
import Link from '../../components/LinkComponent';
import Button from '../../components/Button';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import useClient from '../../services/clients/useClient';

function Client({ match }) {
  const history = useHistory();
  const config = useConfig();
  const { clientId } = match.params;
  const [isDeleteDialog, setIsDeleting] = useState(false);
  const { data = {}, isLoading } = useClient(clientId);

  const { mutate: deleteClient } = useMutation(
    () => request('DELETE', config.apiUrl, `/v1/clients/${clientId}`),
    {
      onSuccess: () => history.push('/clients'),
      onError: () => setIsDeleting(false),
    },
  );

  const userButtons = (
    <div>
      <Link to={`/clients/${data.uid}/users`} button>
        Manage Users
      </Link>
      <Link to={`/clients/${data.uid}/edit`} button>
        Edit Client
      </Link>
      <Button onClick={() => setIsDeleting(true)} modifiers={['simple']}>
        Delete Client
      </Button>
    </div>
  );

  return (
    <Page name="view-user" loading={isLoading}>
      <Breadcrumbs
        links={[
          {
            url: `/clients`,
            label: 'Clients',
          },
          {
            url: `/clients/${data.uid}`,
            label: data.name,
          },
        ]}
      />

      <ButtonGroup floated>{userButtons}</ButtonGroup>

      <ConfirmationDialog
        isOpen={isDeleteDialog}
        onConfirm={deleteClient}
        onCancel={() => setIsDeleting(false)}
      >
        <h2>Confirm deactivation</h2>
        Are you sure you want to deactivate <strong>{data.name}</strong>?
      </ConfirmationDialog>

      <Title>Client details</Title>

      <Table vertical>
        <TableBody>
          <Row>
            <Col width="150px">Name</Col>
            <Col>{data.name}</Col>
          </Row>
          <Row>
            <Col>Client Id</Col>
            <Col>{data.uid}</Col>
          </Row>
        </TableBody>
      </Table>
      <Footer>
        <Link to="/clients">&lt; Back</Link>
      </Footer>
    </Page>
  );
}

Client.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
};

export default Client;

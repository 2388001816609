import React from 'react';
import PropTypes from 'prop-types';

function Label({ htmlFor, children }) {
  return (
    <label className="Label" htmlFor={htmlFor}>
      {children}
    </label>
  );
}

Label.propTypes = {
  children: PropTypes.string.isRequired,
  htmlFor: PropTypes.string,
};

Label.defaultProps = {
  htmlFor: '',
};

export default Label;

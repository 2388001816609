import React from 'react';
import { useQuery, gql } from '@apollo/client';
import Select from 'react-select';

const GET_USER_FARMS = gql`
  query Farms {
    farms {
      id
      name
    }
  }
`;

function FarmsSelect({ isMulti, farmId, onChange }) {
  const { data: farmsData, loading } = useQuery(GET_USER_FARMS);

  const farmOptions = farmsData?.farms.map(farm => ({
    label: farm.name,
    value: farm.id,
  }));

  const farmValue = farmOptions?.find(farm => `${farm.value}` === farmId);

  return (
    <Select
      isMulti={isMulti}
      placeholder="Select a farm"
      isLoading={loading}
      value={farmValue}
      options={farmOptions || []}
      onChange={onChange}
    />
  );
}

export default FarmsSelect;

import React from 'react';
import Page, { Title } from '../../components/Page';
import Link from '../../components/LinkComponent';
import ButtonGroup from '../../components/ButtonGroup';
import ClientTable from './ClientTable';
import useClients from '../../services/clients/useClients';

function ClientsList() {
  const { data = [], isLoading } = useClients();

  return (
    <Page name="view-clients" loading={isLoading}>
      <ButtonGroup modifiers={['search']}>
        <Link to="/clients/create" button>
          Create Client
        </Link>
      </ButtonGroup>
      <Title>Clients</Title>
      <ClientTable clientsList={data} />
    </Page>
  );
}

export default ClientsList;

import React from 'react';
import PropTypes from 'prop-types';

function Row({ children, modifiers }) {
  const classList = ['Table__Row'];
  classList.push(...modifiers.map(m => (m.length ? `Table__Row--${m}` : '')));

  return <tr className={classList.join(' ')}>{children}</tr>;
}

Row.propTypes = {
  children: PropTypes.node.isRequired,
  modifiers: PropTypes.arrayOf(PropTypes.string),
};

Row.defaultProps = {
  modifiers: [],
};

export default Row;

import React, { useState, useEffect } from 'react';
import Table, { Col, TableBody, TableHeader } from '../../components/Table';
import { ScrollableWrapper, Title } from '../../components/Page';
import TextInput from '../../components/TextInput';
import InputGroup from '../../components/InputGroup';
import ButtonGroup from '../../components/ButtonGroup';

function UsersTable({ userList, title, children }) {
  const [searched, setSearch] = useState('');
  const [filteredUserList, setFilteredUserList] = useState([]);

  useEffect(() => setFilteredUserList(userList), [userList]);

  useEffect(() => {
    setFilteredUserList(
      userList?.filter(({ name }) => name.toLowerCase().includes(searched)),
    );
  }, [searched]);

  return (
    <>
      <Title modifiers={['floated-left']}>{title}</Title>
      <ButtonGroup modifiers={['search']}>
        <InputGroup>
          <TextInput
            type="text"
            name="name"
            placeholder="Search users ..."
            value={searched}
            modifiers={['search']}
            required
            onChange={e => setSearch(e.target.value)}
          />
          <span className="input-group-search" />
        </InputGroup>
      </ButtonGroup>
      <ScrollableWrapper overflow={['height']} height="245" width="100%">
        <Table>
          <TableHeader>
            <Col width="300" header>
              Name
            </Col>
            <Col width="220" header>
              Role
            </Col>
            <Col header>Action</Col>
          </TableHeader>
          <TableBody>{filteredUserList.map(user => children(user))}</TableBody>
        </Table>
      </ScrollableWrapper>
    </>
  );
}

export default UsersTable;

import React from 'react';
import PropTypes from 'prop-types';
import Title from './Title';
import Content from './Content';
import Footer from './Footer';
import Navigation from './Navigation';
import ScrollableWrapper from './ScrollableWrapper';

function Page({ name, loading, wide, children }) {
  const classList = ['Page', `Page--${name}`];
  if (loading) {
    classList.push('Page--loading');
  }

  if (wide) {
    classList.push('Page--wide');
  }

  return (
    <div className={classList.join(' ')}>
      {loading && <div className="spin__box" />}
      {!loading && children}
    </div>
  );
}

Page.propTypes = {
  name: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  wide: PropTypes.bool,
  children: PropTypes.node,
};

Page.defaultProps = {
  loading: false,
  wide: false,
  children: undefined,
};

export default Page;

export { Title, Content, Footer, Navigation, ScrollableWrapper };

import React from 'react';
import Link from '../../components/LinkComponent';
import Table, {
  TableBody,
  Row,
  Col,
  TableHeader,
} from '../../components/Table';

export default function ClientTable({ clientsList }) {
  function renderClients(clients) {
    return clients.map(client => {
      return (
        <Row key={client.uid}>
          <Col>
            <Link to={`/clients/${client.uid}`}>{client.name}</Link>
          </Col>
          <Col>Extra Info</Col>
        </Row>
      );
    });
  }

  return (
    <Table>
      <TableHeader>
        <Col header>Name</Col>
        <Col header>Extra info</Col>
      </TableHeader>
      <TableBody>{renderClients(clientsList)}</TableBody>
    </Table>
  );
}

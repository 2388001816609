import React from 'react';
import PropTypes from 'prop-types';
import Col from './Col';
import Row from './Row';
import TableBody from './TableBody';
import TableHeader from './TableHeader';

function Table({ vertical, align, striped, children }) {
  const classList = ['Table', `Table--align-${align}`];

  classList.push(
    vertical ? 'Table--vertical' : '',
    striped ? 'Table--striped' : '',
  );

  return <table className={classList.join(' ')}>{children}</table>;
}

Table.propTypes = {
  children: PropTypes.node.isRequired,
  vertical: PropTypes.bool,
  align: PropTypes.string,
  striped: PropTypes.bool,
};

Table.defaultProps = {
  vertical: false,
  align: 'left',
  striped: false,
};

export default Table;

export { Col, Row, TableBody, TableHeader };

import { useQuery } from 'react-query';
import { useConfig } from '@hummingbirdtechgroup/wings-config';
import { get } from '@hummingbirdtechgroup/wings-request';

const useUser = (id, options = {}) => {
  const config = useConfig();
  return useQuery(
    ['users', id],
    () => get(config.apiUrl, `/v1/users/${id}`),
    options,
  );
};

export default useUser;

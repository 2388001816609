import React from 'react';
import { useQuery } from 'react-query';
import ReactRouterPropTypes from 'react-router-prop-types';
import { useConfig } from '@hummingbirdtechgroup/wings-config';
import { get } from '@hummingbirdtechgroup/wings-request';
import Page, { Title } from '../../../components/Page';
import Table, { Col, Row, TableBody } from '../../../components/Table';
import ButtonGroup from '../../../components/ButtonGroup';
import Link from '../../../components/LinkComponent';

function Variety({ match }) {
  const id = match.params.varietyId;

  const config = useConfig();
  const { data: varietiesData, isLoading, isError } = useQuery(
    ['varieties'],
    () => get(config.apiUrl, `/v1/varieties`),
  );

  const variety = varietiesData?.find(item => item.id.toString() === id);

  return (
    <Page name="view-variety" loading={isLoading}>
      {variety && (
        <ButtonGroup floated>
          <Link to={`/varieties/${id}/edit`} button>
            Edit
          </Link>
        </ButtonGroup>
      )}
      <Title>Variety details</Title>
      {isError && <div className="ErrorMessage">Server error</div>}
      {!variety && !isError && (
        <div className="ErrorMessage">No variety found</div>
      )}
      {variety && (
        <Table vertical>
          <TableBody>
            <Row>
              <Col width="150px">Name</Col>
              <Col>{variety.name}</Col>
            </Row>
            <Row>
              <Col width="150px">Crop Type</Col>
              <Col>{variety?.crop?.name}</Col>
            </Row>
          </TableBody>
        </Table>
      )}
    </Page>
  );
}

Variety.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
};

export default Variety;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Link from '../../components/LinkComponent';
import Form from '../../components/Form';
import FormRow from '../../components/FormRow';
import TextInput from '../../components/TextInput';
import ButtonGroup from '../../components/ButtonGroup';
import Button from '../../components/Button';

export default function ClientForm({
  onSubmit,
  error,
  clientData,
  buttonName,
}) {
  const [data, setData] = useState(clientData);

  useEffect(() => {
    setData(clientData);
  }, [clientData]);

  function onFieldChange(name) {
    setData({ ...data, name });
  }

  return (
    <Form
      type="user"
      onSubmit={e => {
        e.preventDefault();
        onSubmit(data);
      }}
    >
      <FormRow label="Name" labelFor="name">
        <TextInput
          type="text"
          name="name"
          placeholder="name"
          value={data?.name}
          required
          onChange={e => onFieldChange(e.target.value)}
          customValidity="please enter a name"
        />
      </FormRow>

      {error}

      <ButtonGroup>
        <Button>{`${buttonName} Client`}</Button>
        <Link to="/clients" button>
          Cancel
        </Link>
      </ButtonGroup>
    </Form>
  );
}

ClientForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  clientData: PropTypes.object.isRequired,
  buttonName: PropTypes.string.isRequired,
};

import React, { useState } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { useHistory } from 'react-router-dom';
import Page, { Title, Footer } from '../../components/Page';
import Breadcrumbs from '../../components/Breadcrumbs';
import ButtonGroup from '../../components/ButtonGroup';
import Table, { Col, Row, TableBody } from '../../components/Table';
import Link from '../../components/LinkComponent';
import Button from '../../components/Button';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import useDeleteFarm from '../../services/farms/useDeleteFarm';
import { GET_USER_FARMS } from './FarmsList';
import useFarm from '../../services/farms/useFarm';
import EditFarm from './EditFarm';

function ViewFarm({ match }) {
  const history = useHistory();
  const [isDeleting, setIsDeleting] = useState(false);
  const farmId = match.params.id;

  const { data: farmData, loading } = useFarm(farmId);

  const farm = farmData?.farm || {};

  const [deleteFarm, { loading: deleting }] = useDeleteFarm({
    onCompleted: () => history.push('/farms'),
    refetchQueries: [{ query: GET_USER_FARMS }],
  });

  return (
    <Page name="view-user" loading={loading}>
      <Breadcrumbs
        links={[
          {
            url: `/farms`,
            label: 'Farms',
          },
          {
            url: `/farms/${farmId}`,
            label: farm.name,
          },
        ]}
      />

      <ButtonGroup floated>
        <Button
          onClick={() => setIsDeleting(true)}
          modifiers={['red']}
          disabled={deleting}
        >
          Delete
        </Button>
        <Link to={`/farms/${farmId}/edit`} button>
          Edit
        </Link>
      </ButtonGroup>

      <ConfirmationDialog
        isOpen={isDeleting}
        onConfirm={() => deleteFarm({ variables: { deleteFarmId: farmId } })}
        onCancel={() => setIsDeleting(false)}
      >
        <h2>Confirm deactivation</h2>
        Are you sure you want to delete <strong>{farm.name}</strong>?
      </ConfirmationDialog>

      <Title>Farm details</Title>

      <Table vertical>
        <TableBody>
          <Row>
            <Col width="150px">Name</Col>
            <Col>{farm.name}</Col>
          </Row>
          <Row>
            <Col>Country</Col>
            <Col>{farm.countryCode}</Col>
          </Row>
          <Row>
            <Col>Region</Col>
            <Col>{farm.region}</Col>
          </Row>
          <Row>
            <Col>Client</Col>
            <Col>{farm.clientId}</Col>
          </Row>
        </TableBody>
      </Table>

      <Footer>
        <Link to="/">&lt; Back</Link>
      </Footer>
    </Page>
  );
}

EditFarm.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
};

export default ViewFarm;

import { gql, useMutation } from '@apollo/client';

const CREATE_FARM = gql`
  mutation CreateFarm($createFarmInput: CreateFarmInput!) {
    createFarm(input: $createFarmInput) {
      farm {
        id
        clientId
        countryCode
        name
        region
      }
    }
  }
`;

function useCreateFarm(options) {
  return useMutation(CREATE_FARM, options);
}

export default useCreateFarm;

import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import ReactRouterPropTypes from 'react-router-prop-types';
import { get, post } from '@hummingbirdtechgroup/wings-request';
import { useConfig } from '@hummingbirdtechgroup/wings-config';
import Page, { Title, ScrollableWrapper } from '../../components/Page/index';
import Breadcrumbs from '../../components/Breadcrumbs';
import Table, { Col, Row, TableBody } from '../../components/Table/index';
import Button from '../../components/Button';
import ButtonGroup from '../../components/ButtonGroup';
import Link from '../../components/LinkComponent';
import VarietyForm from './Variety/VarietyForm';
import { sortCollectionByField } from '../../utils/lib';
import useCrops from '../../services/crop/useCrops';

function ViewCropDetails({ match }) {
  const cropId = Number(match.params.cropId);
  const config = useConfig();
  const queryClient = useQueryClient();

  const [isCreatingVariety, setIsCreatingVariety] = useState(false);

  const { data: cropsData, isLoading } = useCrops();

  const { data: varietiesData, isLoading: isVarietiesLoading } = useQuery(
    ['varieties', cropId],
    () => get(config.apiUrl, `/v1/crops/${cropId}/varieties`),
  );

  const { mutate, isError } = useMutation(
    data => post(config.apiUrl, `/v1/crops/${cropId}/varieties`, data),
    {
      onSuccess: data => {
        queryClient.setQueryData(
          ['varieties', cropId],
          [...varietiesData, data],
        );
        setIsCreatingVariety(false);
      },
    },
  );

  const crop = cropsData?.find(cropItem => cropItem.id === cropId) || {};
  const varieties = varietiesData
    ? sortCollectionByField(varietiesData, 'variety')
    : [];

  async function onSubmit(data) {
    mutate(data);
  }

  function renderVarietiesList() {
    return (
      <Row>
        <Col>Varieties</Col>
        <Col>
          {varieties.map(item => (
            <Link key={item.id} to={`/varieties/${item.id}`}>
              {item.name}
              <br />
            </Link>
          ))}
        </Col>
      </Row>
    );
  }

  return (
    <Page name="view-crop-details" loading={isLoading || isVarietiesLoading}>
      <Breadcrumbs
        links={[
          {
            url: `/crops/${crop.id}`,
            label: crop.name,
          },
        ]}
      />
      {!isCreatingVariety && (
        <div>
          <ButtonGroup floated>
            <Button
              onClick={() => setIsCreatingVariety(true)}
              modifiers={['simple']}
            >
              Add variety
            </Button>
          </ButtonGroup>
          <Title>Crop details</Title>
          <ScrollableWrapper overflow={['height']} height="300">
            <Table vertical>
              <TableBody>
                <Row>
                  <Col width="150px">Type</Col>
                  <Col>{crop.name}</Col>
                </Row>
                {renderVarietiesList()}
              </TableBody>
            </Table>
          </ScrollableWrapper>
        </div>
      )}
      {isCreatingVariety && (
        <VarietyForm
          error={isError}
          onCancel={() => {
            setIsCreatingVariety(false);
          }}
          onSubmit={data => onSubmit(data)}
        />
      )}
    </Page>
  );
}

ViewCropDetails.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
};

export default ViewCropDetails;

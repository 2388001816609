import React from 'react';
import PropTypes from 'prop-types';

function TableHeader({ children }) {
  return (
    <thead className="Table__Header">
      <tr>{children}</tr>
    </thead>
  );
}

TableHeader.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
};

export default TableHeader;

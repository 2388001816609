import { gql, useMutation } from '@apollo/client';

const UPDATE_FARM = gql`
  mutation UpdateFarm($updateFarmInput: UpdateFarmInput!) {
    updateFarm(input: $updateFarmInput) {
      farm {
        client
        countryCode
        id
        name
        region
      }
    }
  }
`;

function useUpdateFarm(options) {
  return useMutation(UPDATE_FARM, options);
}

export default useUpdateFarm;

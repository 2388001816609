import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import ButtonGroup from './ButtonGroup';
import Button from './Button';

function ConfirmationDialog({ children, isOpen, onCancel, onConfirm }) {
  return (
    <Modal
      isOpen={isOpen}
      ariaHideApp={false}
      className="Modal"
      overlayClassName="Modal__Overlay"
      style={{
        content: {
          'max-height': '220px',
        },
      }}
    >
      {children}

      <div className="Modal__Controls">
        <ButtonGroup>
          <Button onClick={onConfirm}>Confirm</Button>
          <Button modifiers={['simple']} onClick={onCancel}>
            Cancel
          </Button>
        </ButtonGroup>
      </div>
    </Modal>
  );
}

ConfirmationDialog.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default ConfirmationDialog;

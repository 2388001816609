import React from 'react';
import Page, { Title } from '../../components/Page';
import Link from '../../components/Link';
import Table, { Col, Row, TableBody } from '../../components/Table';
import useCrops from '../../services/crop/useCrops';

function CropsList() {
  const { data = [], isLoading } = useCrops();

  return (
    <Page name="crops" loading={isLoading}>
      <Title>Crops</Title>
      <Table>
        <TableBody>
          {data.map(({ id, name }) => (
            <Row key={id}>
              <Col>
                <Link to={`/crops/${id}`}>{name}</Link>
              </Col>
            </Row>
          ))}
        </TableBody>
      </Table>
    </Page>
  );
}

export default CropsList;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Sidebar from '../Sidebar/Sidebar';

function SidebarLayout({ children }) {
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  return (
    <div
      className={`SidebarLayout ${
        isSidebarVisible ? '' : 'SidebarLayout--hidden-sidebar'
      }`}
    >
      <div className="SidebarLayout__Sidebar">
        <Sidebar
          onClick={() => setIsSidebarVisible(!isSidebarVisible)}
          isVisible={isSidebarVisible}
        />
        &nbsp;
      </div>

      <div className="SidebarLayout__Content">{children}</div>
    </div>
  );
}

SidebarLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SidebarLayout;

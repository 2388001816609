import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

function LinkComponent({ button, modifiers, to, children }) {
  const classList = ['Link'];
  if (button) {
    classList.push('Link--button');
  }
  classList.push(...modifiers.map(m => `Link--${m}`));

  return (
    <NavLink to={to} className={classList.join(' ')}>
      {children}
    </NavLink>
  );
}

LinkComponent.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node,
  button: PropTypes.bool,
  modifiers: PropTypes.arrayOf(PropTypes.string),
};

LinkComponent.defaultProps = {
  children: '',
  button: false,
  modifiers: [],
};

export default LinkComponent;

import React from 'react';
import PropTypes from 'prop-types';
import Link from './LinkComponent';

function renderSeparator(key) {
  return (
    <span key={key} className="Breadcrumbs__Separator">
      &gt;
    </span>
  );
}

function renderChain(rawLinks = []) {
  let links;
  if (!(rawLinks instanceof Array)) {
    links = [{ ...rawLinks }];
  } else {
    links = [...rawLinks];
  }

  const result = [];

  links.forEach(link => {
    result.push(renderSeparator(`${link.label}-separator`));
    result.push(
      <Link key={link.label} to={link.url}>
        {link.label}
      </Link>,
    );
  });

  return result;
}

function Breadcrumbs({ links }) {
  return (
    <div className="Breadcrumbs">
      <Link to="/">Home</Link>
      {renderChain(links)}
    </div>
  );
}

Breadcrumbs.propTypes = {
  links: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
};

export default Breadcrumbs;

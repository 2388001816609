import React, { useMemo, useState } from 'react';
import Page, { Title, Footer, Navigation } from '../../components/Page/index';
import ButtonGroup from '../../components/ButtonGroup';
import InputGroup from '../../components/InputGroup';
import TextInput from '../../components/TextInput';
import Link from '../../components/LinkComponent';
import Table, {
  Col,
  Row,
  TableBody,
  TableHeader,
} from '../../components/Table/index';
import useUsers from '../../services/users/useUsers';

const PAGE_SIZE = 10;

function pageUsers(page, users) {
  const startFrom = PAGE_SIZE * (page - 1);
  return page !== 'All' ? users.slice(startFrom, PAGE_SIZE * page) : users;
}

function filterUsers(users, filter) {
  if (filter.length)
    return users.filter(
      user => user.name.toLowerCase().indexOf(filter.toLowerCase()) > -1,
    );
  return users;
}

function UsersList() {
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState('');

  const { data: allUsers = [], isLoading } = useUsers();

  const filteredUsers = useMemo(() => filterUsers(allUsers, filter), [
    allUsers,
    filter,
  ]);

  const usersPerPage = pageUsers(page, filteredUsers);

  const onChangePage = nextPage => {
    setPage(nextPage);
  };

  const onChangeFilter = newFilter => {
    setFilter(newFilter);
    setPage(1);
  };

  return (
    <Page name="users" loading={isLoading}>
      <ButtonGroup modifiers={['search']}>
        <InputGroup>
          <TextInput
            type="text"
            name="name"
            placeholder="Search users ..."
            value={filter}
            modifiers={['search']}
            required
            onChange={e => onChangeFilter(e.target.value)}
          />
          <span className="input-group-search" />
        </InputGroup>
        <Link to="/users/create" button>
          Create user
        </Link>
      </ButtonGroup>

      <Title>Users</Title>

      <Table>
        <TableHeader>
          <Col header>Name</Col>
          <Col width="100" header>
            Staff
          </Col>
          <Col width="100" header>
            Roles
          </Col>
          <Col header>Clients</Col>
        </TableHeader>

        <TableBody>
          {usersPerPage.map(({ uid, name, staff, clients }) => (
            <Row key={uid}>
              <Col>
                <Link to={`/users/${uid}`}>{name}</Link>
              </Col>
              <Col align="center">{staff.toString()}</Col>
              <Col>{clients.map(client => client.role)}</Col>
              <Col>
                {clients.map(client => (
                  <Link
                    key={client.client_uid}
                    to={`/clients/${client.client_uid}`}
                  >
                    {`${client.client_uid}, `}
                  </Link>
                ))}
              </Col>
            </Row>
          ))}
        </TableBody>
      </Table>
      <Footer>
        <Navigation
          itemPerPage={PAGE_SIZE}
          page={page}
          amount={filteredUsers.length}
          onChangePage={onChangePage}
        />
      </Footer>
    </Page>
  );
}

export default UsersList;

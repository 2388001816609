import React from 'react';
import PropTypes from 'prop-types';
import { Router as ReactRouter } from 'react-router-dom';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ApolloClient, ApolloProvider } from '@apollo/client';
import { AuthProvider } from '@hummingbirdtechgroup/wings-auth';
import { ConfigProvider, useConfig } from '@hummingbirdtechgroup/wings-config';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import queryClient from './queryClient';
import history from './history';
import AppRoutes from './AppRoutes';
import apolloConfig from './apolloConfig';

function App({ Router }) {
  const config = useConfig();

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools />
      <AuthProvider>
        <Router history={history}>
          <ApolloProvider
            client={new ApolloClient(apolloConfig(config.apiUrl))}
          >
            <div className="App">
              <AppRoutes />
            </div>
          </ApolloProvider>
        </Router>
      </AuthProvider>
    </QueryClientProvider>
  );
}

App.propTypes = { Router: PropTypes.func };

function AppContainer({ Router }) {
  const apiEnv = process.env.API_ENV;
  return (
    <ConfigProvider env={apiEnv}>
      <App Router={Router} />
    </ConfigProvider>
  );
}

AppContainer.propTypes = {
  Router: PropTypes.func,
};

AppContainer.defaultProps = {
  Router: ReactRouter,
};

export default AppContainer;

import moment from 'moment/moment';
import { YYYY_MM_DD_HH_SS } from './lib';
import fileDownloader from './fileDownloader';

const regexp = new RegExp(/#/g);

/**
 * @param CsvRecords - Record<string, string | number | undefined>[]
 * */

/**
 * @param data - CsvRecords
 * @param header - Boolean
 * @param concatenator - string
 * @return string
 * */
export function generateCsvContent(data, header = true, concatenator = ';') {
  const csvData = data.map(record => Object.values(record).join(concatenator));
  if (header && data.length) {
    csvData.unshift(Object.keys(data[0]).join(concatenator));
  }
  return csvData.join('\n').replace(regexp, '');
}

/**
 * @param name - string
 * @param csvData - CsvRecords
 * @return void
 * */
export function exportToCsv(name, csvData) {
  let csvContent = 'data:text/csv;charset=utf-8,';
  csvContent += generateCsvContent(csvData);
  const timestamp = moment(new Date()).format(YYYY_MM_DD_HH_SS);
  const fileName = `${name}-${timestamp}.csv`;
  fileDownloader(csvContent, fileName);
}

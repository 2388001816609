import React from 'react';
import PropTypes from 'prop-types';

function InputGroup({ children, modifiers }) {
  const classList = ['InputGroup'];
  classList.push(...modifiers.map(m => `InputGroup--${m}`));

  return <div className={classList.join(' ')}>{children}</div>;
}

InputGroup.propTypes = {
  children: PropTypes.node.isRequired,
  modifiers: PropTypes.arrayOf(PropTypes.string),
};

InputGroup.defaultProps = {
  modifiers: [],
};

export default InputGroup;

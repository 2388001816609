import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import ReactRouterPropTypes from 'react-router-prop-types';
import { useHistory } from 'react-router-dom';
import { get, put } from '@hummingbirdtechgroup/wings-request';
import { useConfig } from '@hummingbirdtechgroup/wings-config';
import Page, { Title } from '../../../components/Page';
import VarietyForm from './VarietyForm';

function EditVariety({ match }) {
  const id = match.params.varietyId;
  const history = useHistory();
  const config = useConfig();
  const queryClient = useQueryClient();

  const { data: varietiesData, isLoading, isError } = useQuery(
    ['varieties'],
    () => get(config.apiUrl, `/v1/varieties`),
  );

  const { mutate } = useMutation(
    data => put(config.apiUrl, `/v1/varieties/${id}`, data),
    {
      onSuccess: (data, variables) => {
        queryClient.setQueryData(
          ['varieties'],
          varietiesData.map(item =>
            item.id.toString() === id
              ? { ...item, name: variables?.name }
              : item,
          ),
        );
        history.push(`/varieties/${id}`);
      },
    },
  );

  const variety = varietiesData?.find(item => item.id.toString() === id);

  return (
    <Page name="edit-variety" loading={isLoading}>
      <Title>Edit variety details</Title>
      {isError && <div className="ErrorMessage">Server error</div>}
      {!variety && !isError && (
        <div className="ErrorMessage">No variety found</div>
      )}
      {variety && (
        <VarietyForm
          error={isError}
          defaultValue={variety.name}
          onCancel={() => history.push(`/varieties/${id}`)}
          onSubmit={data => mutate(data)}
        />
      )}
    </Page>
  );
}

EditVariety.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
};

export default EditVariety;

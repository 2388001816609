import React, { useState } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { useHistory } from 'react-router-dom';
import Page, { Title, Footer } from '../../components/Page';
import Breadcrumbs from '../../components/Breadcrumbs';
import ButtonGroup from '../../components/ButtonGroup';
import Table, { Col, Row, TableBody } from '../../components/Table';
import Link from '../../components/LinkComponent';
import Button from '../../components/Button';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import EditUser from './EditUser';
import useUser from '../../services/users/useUser';
import useUserRemove from '../../services/users/useUserRemove';

function ViewUser({ match }) {
  const history = useHistory();
  const [isDeleting, setIsDeleting] = useState(false);
  const userId = match.params.id;

  const { data: user = {}, isLoading } = useUser(userId);

  const { mutate: deleteUser } = useUserRemove(userId, {
    onSuccess: () => history.push('/users'),
    onError: () => setIsDeleting(false),
  });

  return (
    <Page name="view-user" loading={isLoading}>
      <Breadcrumbs
        links={[
          {
            url: `/users`,
            label: 'Users',
          },
          {
            url: `/users/${userId}`,
            label: user.name,
          },
        ]}
      />

      <ButtonGroup floated>
        <Button onClick={() => setIsDeleting(true)} modifiers={['red']}>
          Delete
        </Button>
        <Link to={`/users/${userId}/edit`} button>
          Edit
        </Link>
      </ButtonGroup>

      <ConfirmationDialog
        isOpen={isDeleting}
        onConfirm={() => deleteUser()}
        onCancel={() => setIsDeleting(false)}
      >
        <h2>Confirm deactivation</h2>
        Are you sure you want to delete <strong>{user.name}</strong>?
      </ConfirmationDialog>

      <Title>User details</Title>

      <Table vertical>
        <TableBody>
          <Row>
            <Col width="150px">Name</Col>
            <Col>{user.name}</Col>
          </Row>
          <Row>
            <Col>Email</Col>
            <Col>{user.email}</Col>
          </Row>
          <Row>
            <Col>Phone</Col>
            <Col>{user.phone_number}</Col>
          </Row>
          <Row>
            <Col>Staff</Col>
            <Col>{user.staff?.toString()}</Col>
          </Row>
          <Row>
            <Col>Roles</Col>
            <Col>{user.clients?.map(client => client.role)}</Col>
          </Row>
          <Row>
            <Col>Clients</Col>
            <Col>
              {user.clients?.map(client => (
                <Link
                  key={client.client_uid}
                  to={`/clients/${client.client_uid}`}
                >
                  {`${client.client_uid}, `}
                </Link>
              ))}
            </Col>
          </Row>
        </TableBody>
      </Table>

      <Footer>
        <Link to="/">&lt; Back</Link>
      </Footer>
    </Page>
  );
}

EditUser.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
};

export default ViewUser;

import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import ButtonGroup from '../ButtonGroup';
import Link from '../LinkComponent';
import Button from '../Button';
import configByEnv from '../../config';

const { uploaderUrl } = configByEnv[process.env.API_ENV];

function Sidebar({ history, isVisible, onClick }) {
  return (
    <div className={`Sidebar ${isVisible ? '' : 'Sidebar--closed'}`}>
      <div className="Sidebar__Logo">
        <Link to="/">Hummingbird Admin</Link>
      </div>

      <div className="Sidebar__VisibilitySwitcher">
        <a
          href=""
          onClick={e => {
            e.preventDefault();
            onClick();
          }}
          className="Sidebar__VisibilitySwitcherButton"
        >
          toggle sidebar
        </a>
      </div>

      <ul className="Sidebar__Links">
        <li>
          <Link to="/users">Users</Link>
        </li>
        <li>
          <Link to="/clients">Clients</Link>
        </li>
        <li>
          <Link to="/farms">Farms</Link>
        </li>
        <li>
          <Link to="/surveys">Surveys</Link>
        </li>
        <li>
          <Link to="/crops">Crops</Link>
        </li>

        <li>
          <a
            className="uploaderUrl, Link"
            target="_blank"
            rel="noreferrer"
            href={`${uploaderUrl}/upload`}
          >
            Uploads
          </a>
        </li>
      </ul>

      <ButtonGroup modifiers={['floated', 'float-left']}>
        <Button modifiers={['simple']} onClick={() => history.push('/logout')}>
          Logout
        </Button>
      </ButtonGroup>
    </div>
  );
}

Sidebar.propTypes = {
  history: PropTypes.object.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default withRouter(Sidebar);

import React from 'react';
import { useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { get } from 'lodash';
import CompositePage from '../../components/CompositePage';
import { useSurveyQuery } from '../../services/survey';
import ViewSurveyDetails from './ViewSurveyDetails';
import ViewSurveyDetailsSpectrum from './ViewSurveyDetailsSpectrum';
import ViewSurveyOrthomosaics from './ViewSurveyOrthomosaics';
import SurveyAnalysesTable from './SurveyAnalysesTable';

const GET_FIELD = gql`
  query Field_ViewSurvey($fieldId: ID!) {
    field(id: $fieldId) {
      id
      name
      farm {
        id
        name
        growingSeasons {
          id
          name
          startDate
          endDate
        }
      }
    }
  }
`;

function ViewSurvey() {
  const { surveyId } = useParams();

  const { data: survey, isLoading } = useSurveyQuery(surveyId, {
    enabled: Boolean(surveyId),
  });

  const flightId = get(survey, 'preprocessed_images[0].flight_id');
  const orthos = get(survey, 'preprocessed_images', []);

  const { data: fieldData, loading } = useQuery(GET_FIELD, {
    variables: { fieldId: survey?.field_id },
    skip: !survey?.field_id,
  });
  const field = get(fieldData, 'field');
  const farm = get(fieldData, 'field.farm');
  const seasons = get(fieldData, 'field.farm.growingSeasons', []);

  if (isLoading || loading || !survey) return null;

  const isUAV = survey.source_type === 'UAV';
  return (
    <CompositePage name="view-survey">
      <CompositePage name="view-survey-data">
        <ViewSurveyDetails
          survey={survey}
          seasons={seasons}
          farm={farm}
          field={field}
          flightId={flightId}
        />
        {isUAV && <ViewSurveyDetailsSpectrum orthos={orthos} />}
      </CompositePage>

      <CompositePage name="view-survey-details">
        {isUAV && <ViewSurveyOrthomosaics orthos={orthos} />}
        {surveyId && <SurveyAnalysesTable surveyId={surveyId} />}
      </CompositePage>
    </CompositePage>
  );
}

export default ViewSurvey;

import React from 'react';
import moment from 'moment/moment';
import { get, find } from 'lodash';
import { Col, Row } from '../../components/Table';
// import Checkbox from '../../components/Checkbox';
import productNames from '../../constants/productNames';
import Button from '../../components/Button';
import ErrorMessage from '../../components/ErrorMessage';
import useProductMutation from '../../services/product/useProductMutation';

const SurveyAnalysesRow = ({
  analysis,
  surveyId,
  // checked,
  isAnalysisLoading,
  // selectAnalysis,
}) => {
  const {
    id,
    // product_type_id,
    product_type_name,
    status,
    created_at,
    error,
    enabled,
    files,
  } = analysis;
  const { mutate, isLoading: isPatchLoading } = useProductMutation(
    id,
    surveyId,
  );

  const path = find(files, { data_type: 'Report' })?.path;
  if (path?.includes('https'))
    path.replace('gs://', 'https://console.cloud.google.com/');

  return (
    <>
      <Row>
        <Col>
          {/* disable re-run analysis functionality for now - rd 02/09/21 */}
          {/* <Checkbox */}
          {/*  key={id} */}
          {/*  value={id} */}
          {/*  label="" */}
          {/*  name={product_type_id} */}
          {/*  checked={checked} */}
          {/*  onChange={() => selectAnalysis(analysis.id)} */}
          {/* /> */}
          &nbsp;
        </Col>
        <Col>{get(productNames, product_type_name, product_type_name)}</Col>
        <Col>{moment(created_at).format('YY/MM/DD ddd HH:mm:ss')}</Col>
        <Col>{status}</Col>
        <Col>
          {path ? (
            <a href={path} target="_blank" rel="noopener noreferrer">
              View
            </a>
          ) : (
            'Not Available'
          )}
        </Col>
        <Col>
          {enabled === null && (
            <ErrorMessage modifiers={['small']}>Not available</ErrorMessage>
          )}

          {enabled && (
            <Button
              modifiers={['small', 'red']}
              onClick={() => mutate({ enabled: false })}
              disabled={isPatchLoading || isAnalysisLoading}
            >
              Disable
            </Button>
          )}
          {enabled === false && (
            <Button
              modifiers={['small', 'simple']}
              onClick={() => mutate({ enabled: true })}
              disabled={isPatchLoading || isAnalysisLoading}
            >
              Enable
            </Button>
          )}
        </Col>
      </Row>
      {error && (
        <Row>
          <Col colSpan="5">
            <ErrorMessage modifiers={['small']}>{error}</ErrorMessage>
          </Col>
        </Row>
      )}
    </>
  );
};
export default SurveyAnalysesRow;

import { QueryClient } from 'react-query';
import { hasTokenExpired } from '@hummingbirdtechgroup/wings-auth';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 300000,
      onError: () => {
        // check if token valid on api failed, no way to check status code for 401
        if (hasTokenExpired()) {
          document.location.href = '/logout';
        }
      },
    },
  },
});

export default queryClient;

import React from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';
import { useConfig } from '@hummingbirdtechgroup/wings-config';
import { post } from '@hummingbirdtechgroup/wings-request';
import Page, { Title } from '../../components/Page';
import Breadcrumbs from '../../components/Breadcrumbs';
import UserForm from './UserForm';
import ButtonGroup from '../../components/ButtonGroup';
import Button from '../../components/Button';
import Link from '../../components/LinkComponent';
import ErrorMessage from '../../components/ErrorMessage';

function CreateUser() {
  const config = useConfig();
  const history = useHistory();

  const { mutate, isError } = useMutation(
    data => {
      return post(config.apiUrl, '/v1/users', data);
    },
    {
      onSuccess: data => history.push(`/users/${data.uid}`),
    },
  );

  return (
    <Page name="create-user">
      <Breadcrumbs
        links={[
          {
            url: `/users`,
            label: 'Users',
          },
          {
            url: '/users/create',
            label: 'Create user',
          },
        ]}
      />

      <Title>Create user</Title>

      <UserForm
        error={
          isError && (
            <ErrorMessage>
              There was an error creating the user, please check the data
              entered and try again.
            </ErrorMessage>
          )
        }
        footer={
          <ButtonGroup>
            <Button>Create user</Button>
            <Link to="/" button>
              Cancel
            </Link>
          </ButtonGroup>
        }
        onSubmit={data => mutate(data)}
      />
    </Page>
  );
}

CreateUser.propTypes = {};

export default CreateUser;

import ViewUser from './scenes/Users/ViewUser';
import CreateUser from './scenes/Users/CreateUser';
import EditUser from './scenes/Users/EditUser';
import ViewSurvey from './scenes/Survey/ViewSurvey';
import ViewCrop from './scenes/Crops/ViewCrop';
import ViewVariety from './scenes/Crops/Variety/Variety';
import EditVariety from './scenes/Crops/Variety/EditVariety';
import UsersList from './scenes/Users/UsersList';
import FieldSurveys from './scenes/Surveys';
import CropsList from './scenes/Crops/CropsList';
import Client from './scenes/Clients/Client';
import ViewClients from './scenes/Clients/ViewClients';
import { CreateFarm, ViewFarm, EditFarm, FarmsList } from './scenes/Farms';
import EditClient from './scenes/Clients/EditClient';
import CreateClient from './scenes/Clients/CreateClient';
import ManageUsers from './scenes/Clients/ManageUsers';

export default {
  '/': UsersList,
  '/clients': ViewClients,
  '/clients/create': CreateClient,
  '/clients/:clientId': Client,
  '/clients/:clientId/edit': EditClient,
  '/clients/:clientId/users': ManageUsers,
  '/farms': FarmsList,
  '/farms/create': CreateFarm,
  '/farms/:id': ViewFarm,
  '/farms/:id/edit': EditFarm,
  '/users': UsersList,
  '/users/create': CreateUser,
  '/users/:id': ViewUser,
  '/users/:id/edit': EditUser,
  '/crops/': CropsList,
  '/crops/:cropId': ViewCrop,
  '/varieties/:varietyId': ViewVariety,
  '/varieties/:varietyId/edit': EditVariety,
  '/surveys': FieldSurveys,
  '/surveys/:surveyId': ViewSurvey,
};

import { useQuery } from 'react-query';
import { get } from '@hummingbirdtechgroup/wings-request';
import { useConfig } from '@hummingbirdtechgroup/wings-config';
import { stringify } from 'qs';

const useFlightsQuery = (params, options = {}) => {
  const { startDate, endDate } = params;
  const config = useConfig();
  return useQuery(
    ['flights-query', startDate, endDate],
    () =>
      get(
        config.apiUrl,
        `/v1/flights/?${stringify(
          {
            start_date: startDate,
            end_date: endDate,
          },
          { skipNulls: true },
        )}`,
      ).then(res => res.flights),
    {
      ...options,
      enabled: Boolean(startDate && endDate && options.enabled),
    },
  );
};

export default useFlightsQuery;

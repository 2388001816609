const productNames = {
  'analysis-gap_line': 'Gap Line',
  'analysis-gridded_ndvi': 'Gridded NDVI',
  'analysis-disease': 'Disease',
  'analysis-variable_rate_application': 'Variable Rate Application',
  'analysis-green_area_index': 'Green Area Index',
  'application-fungicide/analysis-gridded_ndvi': 'Fungicide (Gridded NDVI)',
  'analysis-weed_detection': 'Weed Detection',
  'application-post_em_herbicide/analysis-wide_row_weed_detection':
    'Post Emergence Herbicide (Wide Row Weed Detection)',
  'application-post_emergence_herbicide/analysis-weed_detection':
    'Post Emergence Herbicide (Weed Detection)',
  'application-pgr/analysis-gridded_ndvi':
    'Plant Growth Regulator (Gridded NDVI)',
  'application-pgr/analysis-green_area_index':
    'Plant Growth Regulator (Green Area Index)',
  'application-desiccation/analysis-gridded_ndvi': 'Desiccation (Gridded NDVI)',
  'analysis-gridded_ndvi_max': 'Gridded NDVI Max',
  'application-pre_emergence_herbicide/analysis-gridded_ndvi_max':
    'Pre Emergence Herbicide (Gridded NDVI Max)',
  'analysis-green_area_index_raster': 'Green Area Index (Raster)',
  'analysis-plant_population': 'Plant Population',
  'analysis-gridded_ndvi2': 'Gridded NDVI 2',
  'analysis-yellow_bud_index': 'Yellow Bud Index',
  'analysis-lodging_risk': 'Lodging Risk',
  'application-linear/analysis-gridded_ndvi': 'Linear (Gridded NDVI)',
  'analysis-canopy_coverage': 'Canopy Coverage',
  'analysis-rgb_statistics': 'RGB Statistics',
  'analysis-plant_sizing': 'Plant Sizing',
  'analysis-mean_size': 'Mean Size',
  'analysis-raster_ndvi': 'NDVI (Raster)',
  'analysis-mean_size_zone_level': 'Mean Size (Zone Level)',
  'analysis-photographic_imagery': 'Photographic Imagery',
  'analysis-crop_health': 'Crop Health',
  'analysis-ndvi_scaled_grid': 'NDVI (Scaled Grid)',
  'analysis-field_classifier': 'Field Classifier',
  'analysis-vari': 'VARI',
  'analysis-terrain': 'Terrain',
  'analysis-pre_emergence_weed_detection': 'Pre Emergence Weed Detection',
  'analysis-reflectance_imagery': 'Reflectance Imagery',
  'analysis-plant_sizing_contours': 'Plant Size (Contours)',
  'application-nitrogen/analysis-mean_size': 'Nitrogen (Mean Size)',
  'analysis-plant_population_zone_level': 'Plant Population (Zone Level)',
};

export default productNames;

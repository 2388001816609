import React from 'react';
import PropTypes from 'prop-types';

function ButtonGroup({ floated, children, modifiers }) {
  const classList = ['ButtonGroup'];
  classList.push(...modifiers.map(m => `ButtonGroup--${m}`));
  if (floated) {
    classList.push('ButtonGroup--floated');
  }

  return <div className={classList.join(' ')}>{children}</div>;
}

ButtonGroup.propTypes = {
  children: PropTypes.node.isRequired,
  floated: PropTypes.bool,
  modifiers: PropTypes.arrayOf(PropTypes.string),
};

ButtonGroup.defaultProps = {
  floated: false,
  modifiers: [],
};

export default ButtonGroup;

import React from 'react';
import PropTypes from 'prop-types';
import ButtonGroup from '../../components/ButtonGroup';
import Button from '../../components/Button';
import ErrorMessage from '../../components/ErrorMessage';
import { useEditSurveyMutation } from '../../services/survey';

function FieldSurveyButtonGroup({ surveyId, enabled }) {
  const { mutate: updateSurvey, isError } = useEditSurveyMutation();

  const displayDisableButton = enabled && !isError;
  const displaySurveyDisabledMessage = !enabled && !isError;
  const displayBadRequestMessage = isError;
  // todo:<survey-service> Use the status to display this message - SFR 2021-03-30
  // const displaySurveyProcessingMessage = !survey.in_platform;
  const handleDisableSurvey = event => {
    event.preventDefault();
    updateSurvey({ id: surveyId, enabled: !enabled });
  };

  return (
    <ButtonGroup floated>
      {displayDisableButton && (
        <Button onClick={handleDisableSurvey} modifiers={['red']}>
          Disable
        </Button>
      )}
      {displaySurveyDisabledMessage && (
        <ErrorMessage>Survey disabled</ErrorMessage>
      )}
      {displayBadRequestMessage && (
        <ErrorMessage>Bad request, please try again later.</ErrorMessage>
      )}
      {/* {displaySurveyProcessingMessage && <p>Not Finished Processing</p>} */}
    </ButtonGroup>
  );
}

FieldSurveyButtonGroup.propTypes = {
  surveyId: PropTypes.string.isRequired,
  enabled: PropTypes.bool.isRequired,
};

export default FieldSurveyButtonGroup;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Footer } from '../../components/Page';
import Form from '../../components/Form';
import FormRow from '../../components/FormRow';
import TextInput from '../../components/TextInput';
import Select from '../../components/Select';
import { countryCodes } from '../../constants/countryCodes';
import ClientSelector from '../Clients/ClientSelector';

function FarmForm(props) {
  const [state, setState] = useState({
    name: '',
    region: '',
    clientId: '',
    countryCode: '',
    ...props.data,
  });

  const onFieldChange = e => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = e => {
    e.preventDefault();
    props.onSubmit(state);
  };

  const onCountryChange = e => {
    setState({
      ...state,
      countryCode: e.target.value,
    });
  };

  const onClientChange = e => {
    const clientUid = e.target.value;

    setState({
      ...state,
      clientId: clientUid,
    });
  };

  return (
    <Form type="farm" onSubmit={onSubmit}>
      <FormRow label="Name" labelFor="name">
        <TextInput
          type="text"
          name="name"
          placeholder="farm name"
          value={state.name}
          required
          onChange={onFieldChange}
        />
      </FormRow>

      <FormRow label="Country" labelFor="country">
        <Select
          placeholder={'Select country'}
          name="season"
          value={state.countryCode}
          options={countryCodes}
          onChange={onCountryChange}
        />
      </FormRow>

      <FormRow label="Region" labelFor="region">
        <TextInput
          type="text"
          name="region"
          placeholder="region"
          value={state.region}
          required
          onChange={onFieldChange}
        />
      </FormRow>

      <FormRow label="Client" labelFor="client">
        <ClientSelector
          name="client"
          value={state.clientId}
          handleChange={onClientChange}
        />
      </FormRow>

      {props.error}

      <Footer>{props.footer}</Footer>
    </Form>
  );
}

FarmForm.propTypes = {
  footer: PropTypes.node.isRequired,
  error: PropTypes.node.isRequired,
  onSubmit: PropTypes.func.isRequired,
  data: PropTypes.objectOf(PropTypes.any),
};

FarmForm.defaultProps = {
  data: {},
};

export default FarmForm;

import React from 'react';
import PropTypes from 'prop-types';

function TableBody({ children }) {
  return <tbody className="Table__Body">{children}</tbody>;
}

TableBody.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TableBody;

import React, { Component } from 'react';
import PropTypes from 'prop-types';

class TextInput extends Component {
  constructor(props) {
    super(props);

    this.onInput = this.onInput.bind(this);
    this.onInvalid = this.onInvalid.bind(this);
  }

  onInput(e) {
    if (!this.props.customValidity) {
      return;
    }

    e.target.setCustomValidity('');
  }

  onInvalid(e) {
    if (!this.props.customValidity) {
      return;
    }

    e.target.setCustomValidity(this.props.customValidity);
  }

  render() {
    const { customValidity, modifiers, ...attrs } = this.props;
    const classList = ['TextInput'];
    classList.push(...modifiers.map(m => `TextInput--${m}`));

    return (
      <input
        className={classList.join(' ')}
        id={attrs.name}
        data-validity={customValidity}
        {...attrs}
        onInput={this.onInput}
        onInvalid={this.onInvalid}
      />
    );
  }
}

TextInput.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  value: PropTypes.string,
  customValidity: PropTypes.string,
  modifiers: PropTypes.arrayOf(PropTypes.string),
};

TextInput.defaultProps = {
  type: 'text',
  value: '',
  customValidity: undefined,
  modifiers: [],
};

export default TextInput;

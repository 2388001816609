import React from 'react';
import PropTypes from 'prop-types';

function CompositePage({ name, stretch, children }) {
  const classList = ['CompositePage', `CompositePage--${name}`];
  if (stretch) {
    classList.push('CompositePage--stretch');
  }

  return <div className={classList.join(' ')}>{children}</div>;
}

CompositePage.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  stretch: PropTypes.bool,
};

CompositePage.defaultProps = {
  stretch: false,
};

export default CompositePage;

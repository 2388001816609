import React from 'react';
import PropTypes from 'prop-types';
import Label from './Label';

function FormRow({ label, labelFor, children, modifiers }) {
  const classList = ['FormRow'];
  classList.push(...modifiers.map(m => (m.length ? `FormRow--${m}` : '')));
  return (
    <div className={classList.join(' ')}>
      <Label htmlFor={labelFor}>{label}</Label>
      <div className="FormRow__Control">{children}</div>
    </div>
  );
}

FormRow.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string,
  labelFor: PropTypes.string,
  modifiers: PropTypes.arrayOf(PropTypes.string),
};

FormRow.defaultProps = {
  label: '',
  labelFor: '',
  modifiers: [],
};

export default FormRow;

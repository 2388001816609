import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Footer } from '../../../components/Page';
import Form from '../../../components/Form';
import FormRow from '../../../components/FormRow';
import TextInput from '../../../components/TextInput';
import Button from '../../../components/Button';
import ButtonGroup from '../../../components/ButtonGroup';
import ErrorMessage from '../../../components/ErrorMessage';

class VarietyForm extends Component {
  constructor(props) {
    super(props);

    this.onSubmit = ::this.onSubmit;

    this.state = {
      name: this.props.defaultValue,
    };
  }

  onSubmit(e) {
    e.preventDefault();
    const data = { name: this.state.name };
    this.props.onSubmit(data);
  }

  displayError() {
    if (this.props.error) {
      return (
        <ErrorMessage>
          There was an error saving the variety, please check the data entered
          and try again.
        </ErrorMessage>
      );
    }
    return <div />;
  }

  render() {
    return (
      <Form type="farm" onSubmit={this.onSubmit}>
        <FormRow label="Name" labelFor="name">
          <TextInput
            type="text"
            name="name"
            placeholder="Variety name"
            value={this.state.name}
            maxLength="64"
            required
            onChange={e => this.setState({ name: e.target.value })}
          />
        </FormRow>

        {this.displayError()}

        <Footer>
          <ButtonGroup>
            <Button>Save</Button>
            <Button
              type="button"
              onClick={() => this.props.onCancel()}
              modifiers={['simple']}
            >
              Cancel
            </Button>
          </ButtonGroup>
        </Footer>
      </Form>
    );
  }
}

VarietyForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  error: PropTypes.bool,
  defaultValue: PropTypes.string.isRequired,
};

VarietyForm.defaultProps = {
  error: false,
  defaultValue: '',
};

export default VarietyForm;
